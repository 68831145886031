import axios from 'axios';
import { maskPhoneNumber, cleanPhoneNumber } from '../../../_mexablock/helpers';
const API_URL = process.env.REACT_APP_API;

export async function getSelectEmpresas() {
  const response = await axios.get(`${API_URL}catalogos/empresas`);
  const data = response.data.doc.data;
  let dataSelect: any = data.rows;

  dataSelect.forEach((row: any) => {
    row.label =
      row.name.trim() ||
      `${row.first_name.trim()} ${row.father_last_name?.trim() || ''}`.trim() ||
      'Sin nombre';
    row.value = row.id;
  });

  return dataSelect;
}

export async function getAllEmpresas(filters: any) {
  // let search: string = '';
  // if (orderValues) {
  //   if (orderValues.columnName !== '') {
  //     search += `?company.name=${orderValues.sortOrder}`;
  //   }
  // }
  // if (filters) {
  //   if (filters.nombre !== '') {
  //     search += `?nameOpLike=%${filters.nombre}%`;
  //   }
  // }

  const response = await axios.get(`${API_URL}catalogos/empresas?sort=id`);

  // const response = await axios.get(
  //   `${API_URL}catalogos/empresas?sort=id&limit=${startIndex}&page=${endIndex}${search}`
  // );

  const data = response.data.doc.data;
  return data;
}

export async function addEmpresas(values: any) {
  let body = values;

  let formData = new FormData();
  formData.append('type_contributor_id', body.type_contributor_id);
  formData.append('type_id', body.type_id);
  formData.append('street', body.street);
  formData.append('city', body.city);
  formData.append('state', body.state);
  formData.append('postal_code', body.postal_code);
  formData.append('type_commission_id', body.type_commission_id);
  formData.append('commission_percent', body.commission_percent);
  formData.append('name', body.name);
  formData.append('business_name', body.business_name);
  formData.append('first_name', body.first_name);
  formData.append('father_last_name', body.father_last_name);
  formData.append('mother_last_name', body.mother_last_name);
  formData.append('birthdate', body.birthdate);
  formData.append('gender_id', body.gender_id);
  formData.append('email', body.email);
  formData.append('phone', body.phone);
  formData.append('interbank_key', body.interbank_key);
  formData.append('bank_name', body.bank_name);
  formData.append('account_owner_first_name', body.account_owner_first_name);
  formData.append('account_owner_last_name', body.account_owner_last_name);
  formData.append('cfdi_id', body.cfdi_id);
  formData.append('billing_mail', body.billing_mail);
  formData.append('rfc', body.rfc);
  formData.append('pdfFile', body.pdfFile);
  const response = await axios({
    method: 'post',
    url: `${API_URL}catalogos/empresas/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
}

export async function updateEmpresas(values: any) {
  let body = values;
  if (body.user.phone) {
    body.user.phone = body.user.phoneFormat;
  }
  const response = await axios.put(
    `${API_URL}catalogos/empresas/company/${body.id}/user/${body.user.id}`,
    body
  );
  return response;
}

export async function updEmpresaSocio(values: any, id: number) {
  let body = values;
  let idDistribuidor = id;
  console.log('valores update: ', body);
  let formData = new FormData();
  if (body.type_contributor_id === 2) {
    formData.append('street', body.street);
    formData.append('city', body.city);
    formData.append('state', body.state);
    formData.append(
      'postal_code',
      body.postal_code === 0 ? '' : body.postal_code
    );
  }
  if (body.interbank_key && body.interbank_key.trim() !== '') {
    formData.append('interbank_key', body.interbank_key);
  }
  formData.append('type_id', body.type_id);
  formData.append('type_contributor_id', body.type_contributor_id);
  formData.append('type_commission_id', body.type_commission_id);
  formData.append('commission_percent', body.commission_percent);
  formData.append('name', body.name);
  formData.append('business_name', body.business_name);
  formData.append('first_name', body.first_name);
  formData.append('father_last_name', body.father_last_name);
  formData.append('mother_last_name', body.mother_last_name);
  formData.append('birthdate', body.birthdate);
  formData.append('gender_id', body.gender_id);
  formData.append('email', body.email);
  formData.append('phone', body.phone);
  formData.append('bank_name', body.bank_name);
  formData.append('account_owner_first_name', body.account_owner_first_name);
  formData.append('account_owner_last_name', body.account_owner_last_name);
  formData.append('cfdi_id', body.cfdi_id);
  formData.append('billing_mail', body.billing_mail);
  formData.append('rfc', body.rfc);
  formData.append('pdfFile', body.pdfFile);
  const response = await axios({
    method: 'put',
    url: `${API_URL}catalogos/empresas/${idDistribuidor}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
}

export async function getOneEmpresas(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/empresas/${id_row}`);
  let responseData = response.data.doc.data;
  responseData.user.phone = maskPhoneNumber(responseData.user.phone);
  responseData.user.phoneFormat = cleanPhoneNumber(responseData.user.phone);
  if (responseData.type.code === 'SOC') {
    responseData.name = '';
  }
  return responseData;
}

export async function removeEmpresas(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/empresas/${id}`);
  return response;
}

export async function getEmpresaById(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/empresas/${id_row}`);
  let responseData = response.data.doc.data;

  return responseData;
}

export async function removeEmpresaSocio(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/empresas/${id}`);
  return response;
}
