import React, { useState, useEffect } from 'react';
import {
  KTSVG,
  maskPhoneNumber,
  SimpleAlert,
  LoadingGrid,
} from '../../../../_mexablock/helpers';
import { useGetAll } from '../hooks/ClientesHook';
import { Filter } from '../_modals/Filter';
import { TableList } from '../../../../_mexablock/helpers/components/TableList';
import { MenuComponent } from '../../../../_mexablock/assets/ts/components';
import { sendEmail } from '../../../service/clientes/Clientes';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);
  const [initFilterValues, setInitFilterValues] = useState({
    verified_email: 0,
  });
  const { data, loadingRequest } = useGetAll(initFilterValues);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const columns: any = [
    {
      id: 'cliente',
      name: <div className="fw-bolder text-muted me-5">Cliente</div>,
      selector: (row: any) => row.first_name,
      cell: (row: any) =>
        `${row.first_name} ${row.father_last_name} ${row.mother_last_name}`,
      sortable: true,
      width: '25%',
    },
    {
      id: 'email',
      name: <div className="fw-bolder text-muted me-5">Correo</div>,
      selector: (row: any) => row.email,
      cell: (row: any) => row.email,
      sortable: true,
      width: '25%',
    },
    {
      id: 'phone',
      name: <div className="fw-bolder text-muted me-5">Teléfono</div>,
      selector: (row: any) => (row?.phone ? maskPhoneNumber(row.phone) : ''),
      sortable: true,
      width: '20%',
    },
    {
      id: 'verified_email',
      name: <div className="fw-bolder text-muted me-5">Verificado</div>,
      selector: (row: any) => row.verified_email,
      cell: (row: any) => (
        <>
          {row.verified_email ? (
            <KTSVG
              path="/media/icons/duotune/general/gen026.svg"
              className="svg-icon-1 svg-icon-primary ms-5"
            />
          ) : (
            <KTSVG
              path="/media/icons/duotune/general/gen042.svg"
              className="svg-icon-1 svg-icon ms-5"
            />
          )}
        </>
      ),
      sortable: true,
      width: '15%',
    },
    {
      id: 'acciones',
      name: '',
      cell: (row: any) => dropdownRender(row, row.verified_email),
      sortable: false,
      width: '15%',
    },
  ];

  MenuComponent.reinitialization();

  const dropdownRender = (row: any, verified_email: boolean) => {
    return (
      <>
        {!verified_email && (
          <div className="d-flex justify-content-end flex-shrink-0">
            <a
              href="#/"
              data-kt-menu="true"
              className="btn btn-light btn-active-light-primary btn-sm"
              data-kt-menu-trigger="click"
              data-kt-menu-placement="bottom-end"
            >
              Acciones
              <span className="svg-icon svg-icon-5 m-0">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mh-50px"
                >
                  <path
                    d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                    fill="currentColor"
                  ></path>
                </svg>
              </span>
            </a>

            <div
              className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-auto py-4"
              data-kt-menu="true"
            >
              <div className="menu-item px-3">
                <a
                  className="menu-link px-3"
                  href="#/"
                  onClick={() => {
                    handlerSendEmail(row.id);
                  }}
                >
                  <KTSVG
                    path="/media/icons/duotune/communication/com002.svg"
                    className="svg-icon-1 svg-icon-primary"
                  />
                  <div className="svg-icon-1 svg-icon ms-3">
                    Enviar correo de verificación
                  </div>
                </a>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  const handlerSendEmail = (id: number) => {
    sendEmail(id)
      .then((res) => {
        const {
          data: { message },
        } = res;
        SimpleAlert(message, 3000, 'success');
      })
      .catch((err) => {
        let resMessageToast: string = '';
        const {
          data: { message },
        } = err.response;
        resMessageToast = `${message}`;
        SimpleAlert(resMessageToast, 3000, 'error');
      });
  };

  const handleFilter = (values: any) => {
    setInitFilterValues({
      verified_email: Number(values.verified_email),
    });
  };
  const handleFilterClean = () => {
    setInitFilterValues({ verified_email: 0 });
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={handleFilterClean}
                onSearchFilter={handleFilter}
              />
              <div className="card-toolbar" title="Nuevo registro"></div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export { View };
