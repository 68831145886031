import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import {
  KTSVG,
  SimpleAlert,
  maskPhoneNumber,
} from '../../../../_mexablock/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { AddNumTracking } from '../../../service/envios/envios';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  infoEnvios: any;
};

const DetailsModal: React.FC<Props> = ({ show, handleClose, infoEnvios }) => {
  const [loading, setLoading] = useState(false);

  const EnvioSchema = Yup.object().shape({
    trackingNumber: Yup.string()
      .matches(
        /^[a-zA-Z0-9-]+$/,
        'El número de seguimiento solo puede contener letras, números y guiones.'
      )
      .min(9, 'El número de seguimiento debe ser de al menos 9 dígitos')
      .max(40, 'El número de seguimiento no puede exceder 40 caracteres.')
      .required('Número de seguimiento es requerido.'),
    // trackingDate: Yup.date()
    //   .typeError('Fecha inválida.')
    //   .required('La fecha de generación de guía es requerida.'),
  });

  const formEnvio = useFormik({
    initialValues: {
      trackingNumber: infoEnvios?.numero_seguimiento
        ? infoEnvios.numero_seguimiento.toString()
        : '',
      trackingDate: infoEnvios?.fecha_generacion_guia
        ? new Date(infoEnvios.fecha_generacion_guia).toISOString().split('T')[0]
        : '',
      id: infoEnvios?.id || 0,
    },
    validationSchema: EnvioSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        AddNumTracking(values)
          .then((res) => {
            const { message, doc } = res;
            if (doc?.data) {
              SimpleAlert(message, 3000, 'success');
            } else {
              SimpleAlert(
                'Ocurrió un error inesperado al actualizar el número de seguimiento.',
                3000,
                'error'
              );
            }
            formEnvio.resetForm();
            setLoading(false);
            handleClose(true);
          })
          .catch((err) => {
            let resMessageToast = 'Ocurrió un problema con la solicitud';
            if (err.response && err.response.data) {
              const { message, details = null } = err.response.data;
              resMessageToast = details?.length ? details[0].message : message;
            } else {
              console.error('Error sin respuesta:', err);
            }
            setLoading(false);
            setSubmitting(false);
            SimpleAlert(resMessageToast, 3000, 'error');
          });
      }, 1000);
    },
  });

  const statusEnvio = (asigEstatus: any) => {
    switch (asigEstatus) {
      case 1:
        return (
          <span className="fw-normal badge badge-light-warning">Pendiente</span>
        );
      case 2:
        return (
          <span className="fw-normal badge badge-light-primary">
            En proceso
          </span>
        );
      case 3:
        return (
          <span className="fw-normal badge badge-light-success">Exitoso</span>
        );
      case 5:
        return (
          <span className="fw-normal badge badge-light-warning">
            Por recolectar
          </span>
        );
      case 6:
        return (
          <span className="fw-normal badge badge-light-success">
            Recolectado
          </span>
        );
      case 4:
        return (
          <span className="fw-normal badge badge-light-danger">Error</span>
        );
    }
  };

  if (!show || !infoEnvios) return null;

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-md"
      show={show}
      //   onHide={() => {
      //     formCallCenter.resetForm();
      //     handleClose(false);
      //   }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 d-flex justify-content-center">
          <div className="row w-100 mb-10 ">
            <div className="col-6 m-0">
              <h1>Detalles de envío</h1>
            </div>
            <div className="col d-flex align-items-center justify-content-start">
              {statusEnvio(infoEnvios.status.id)}
            </div>
            <div className="col-2">
              <div
                className="btn btn-sm btn-icon btn-active-color-primary ms-3 justify-content-end"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleClose(true);
                  formEnvio.resetForm();
                }}
              >
                <KTSVG
                  className="svg-icon-2"
                  path="/media/icons/duotune/arrows/arr061.svg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="row mb-5">
            <div>Identificación</div>
            <div className="col text-muted">
              <div>ID de Pre-registro</div>
            </div>
            <div className="col text-end">
              <div>{infoEnvios.id}</div>
            </div>
          </div>
          <div className="row mb-5">
            <div>Datos del Cliente</div>
            <div className="col text-muted">
              <div>Nombre completo</div>
              <div>Correo electrónico</div>
              <div>Número de teléfono</div>
            </div>
            <div className="col text-end">
              <div>
                {infoEnvios.user.first_name} {infoEnvios.user.father_last_name}
              </div>
              <div>{infoEnvios.user.email}</div>
              <div>{maskPhoneNumber(infoEnvios.phone)}</div>
            </div>
          </div>
          <div className="row mb-5">
            <div>Datos del envío</div>
            <div className="col text-muted">
              <div>Código postal</div>
              <div>Calle y número</div>
              <div>Colonia</div>
              <div>Ciudad y estado</div>
              <div>Referencia</div>
            </div>
            <div className="col text-end">
              <div>{infoEnvios.cp}</div>
              <div>{infoEnvios.street}</div>
              <div>{infoEnvios.colony}</div>
              <div>
                {infoEnvios.city}, {infoEnvios.state}
              </div>
              <div>{infoEnvios.reference}</div>
            </div>
          </div>
          <div className="separator my-10"></div>

          {infoEnvios.is_pickup ? (
            <div className="row mb-5">
              <div>Estatus</div>
              <div className="col text-muted">
                <div>Estatus actual</div>
              </div>
              <div className="col text-end">
                {statusEnvio(infoEnvios.status.id)}
              </div>
            </div>
          ) : (
            <form
              id="kt_modal_generic_form"
              className="form"
              action="#"
              onSubmit={formEnvio.handleSubmit}
            >
              <div className="fv-row mb-9">
                <div className="w-100">
                  <label className="required fw-bold mb-1 w-auto">
                    Número de seguimiento
                  </label>
                </div>
                <input
                  name="trackingNumber"
                  type="text"
                  autoComplete="off"
                  className="form-control mb-3 w-50"
                  value={formEnvio.values.trackingNumber}
                  maxLength={40}
                  minLength={9}
                  disabled={!!infoEnvios?.numero_seguimiento}
                  onChange={(e) => {
                    const value = e.target.value.replace(/[^a-zA-Z0-9-]/g, '');
                    formEnvio.setFieldValue('trackingNumber', value);
                  }}
                />

                {formEnvio.touched.trackingNumber &&
                  formEnvio.errors.trackingNumber && (
                    <div className="fv-plugins-message-container text-danger w-auto">
                      <span role="alert">
                        {formEnvio.errors.trackingNumber}
                      </span>
                    </div>
                  )}
              </div>

              <div className="fv-row mb-9">
                <div className="w-100">
                  <label className="required fw-bold mb-1 w-auto">
                    Fecha de generación de guía
                  </label>
                </div>
                <input
                  type="date"
                  className="form-control w-50"
                  value={
                    formEnvio.values.trackingDate ||
                    new Date().toISOString().split('T')[0]
                  }
                  disabled
                  onKeyDown={(e) => e.preventDefault()}
                ></input>
              </div>
              <div className="text-center pt-10">
                {infoEnvios.is_pickup || infoEnvios?.numero_seguimiento ? (
                  <button
                    className="btn btn-primary me-3"
                    data-kt-users-modal-action="cancel"
                    onClick={() => {
                      handleClose(true);
                      formEnvio.resetForm();
                    }}
                  >
                    Cerrar
                  </button>
                ) : (
                  <button
                    type="submit"
                    className="btn btn-primary me-3"
                    data-kt-users-modal-action="submit"
                    // onClick={() => {
                    //   const { trackingNumber, trackingDate } = formEnvio.values;

                    //   if (!trackingNumber && !trackingDate) {
                    //     handleClose(true);
                    //     formEnvio.resetForm();
                    //   } else {
                    //     formEnvio.validateForm().then((errors) => {
                    //       if (Object.keys(errors).length > 0) {
                    //         formEnvio.setTouched({
                    //           trackingNumber: true,
                    //           trackingDate: true,
                    //         });
                    //       } else {
                    //         formEnvio.handleSubmit();
                    //       }
                    //     });
                    //   }
                    // }}
                    disabled={loading}
                  >
                    {!loading && (
                      <span className="indicator-label">Confirmar</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                )}
              </div>
            </form>
          )}
        </div>
      </div>
    </Modal>
  );
};
export { DetailsModal };
