import React, { useState, useRef, useEffect } from 'react';
import {
  KTSVG,
  SimpleAlert,
  maskPhoneNumber,
  cleanPhoneNumber,
  toAbsoluteUrl,
} from '../../../../_mexablock/helpers';
import { Modal } from 'react-bootstrap-v5';
import { NumericFormat } from 'react-number-format';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { updEmpresaSocio } from '../../../service/empresas/Empresas';
import {
  useSelectGeneric,
  useSelectTypePerson,
} from '../../catalogos/generico/hooks/genericoHook';
import { EmpresaSocioModel } from '../models/EmpresaModel';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  DataRegister: any;
};

const EmpresaSchema = Yup.object().shape({
  type_id: Yup.number().nullable(),
  type_contributor_id: Yup.number().nullable(),
  type_commission_id: Yup.number().nullable(),
  street: Yup.string().max(30, 'Máximo 30 caracteres'),
  city: Yup.string().max(50, 'Máximo 50 caracteres'),
  state: Yup.string().max(50, 'Máximo 50 caracteres'),
  postal_code: Yup.string()
    .nullable()
    .test(
      'is-valid',
      'Debe ser un código postal válido de 5 dígitos',
      (value) => {
        return !value || /^\d{5}$/.test(value);
      }
    ),
  name: Yup.string().max(100, 'Máximo 100 caracteres'),
  business_name: Yup.string().max(100, 'Máximo 100 caracteres'),
  email: Yup.string()
    .email('Formato de correo inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.'),
  billing_mail: Yup.string()
    .email('Formato de correo inválido.')
    .min(3, 'Mínimo 3 caracteres.')
    .max(50, 'Máximo 50 caracteres.'),
  cfdi_id: Yup.number().nullable().min(1, 'Requerido.'),
  pdfFile: Yup.mixed()
    .nullable()
    .test('is-pdf', 'El archivo debe ser un PDF', (value, context) => {
      if (!value || typeof value === 'string') {
        return true;
      }
      return value && value.type === 'application/pdf';
    }),
  rfc: Yup.string().matches(
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/,
    'Formato de RFC inválido'
  ),
  interbank_key: Yup.string().min(18, 'Mínimo 18 caracteres'),
  first_name: Yup.string().max(30, 'Máximo 30 caracteres'),
  mother_last_name: Yup.string().max(30, 'Máximo 30 caracteres'),
  father_last_name: Yup.string().max(30, 'Máximo 30 caracteres'),
  birthdate: Yup.date(),
  phone: Yup.string().min(10, 'Formato inválido'),
  gender_id: Yup.number().nullable().min(1, 'Requerido.'),
  account_owner_first_name: Yup.string().max(30, 'Máximo 30 caracteres'),
  account_owner_last_name: Yup.string().max(30, 'Máximo 30 caracteres'),
  bank_name: Yup.string().max(30, 'Máximo 30 caracteres'),
  commission_percent: Yup.number()
    .max(100, 'El porcentaje no puede ser mayor que 100')
    .min(0, 'El porcentaje no puede ser menor que 0'),
});

const currentDate = new Date();
const fechaMaxima = new Date(currentDate);
fechaMaxima.setFullYear(currentDate.getFullYear() - 18);
const maxDate = fechaMaxima.toISOString().split('T')[0];

const UpdateModal: React.FC<Props> = ({ DataRegister, show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const constancyRef = useRef<HTMLInputElement>(null);
  const [documentName, setDocumentName] = useState<string>('');
  const { catalog: genders } = useSelectGeneric('GEN');
  const { catalog: types } = useSelectGeneric('TEMP');
  const { catalog: cfdi } = useSelectGeneric('CFDI');
  const { catalog: tipo_comision } = useSelectGeneric('TDC');
  const { catalogTypePerson: tipo_persona } = useSelectTypePerson();
  const [changeTypeContributor, setChangeTypeContributor] = useState<Number>(0);
  const [changeTypeUser, setChangeTypeUser] = useState<Number>(0);
  const [disInactivo, setDisInactivo] = useState<boolean>(false);
  const [initValuesDis, setInitValuesDis] = useState<EmpresaSocioModel>({
    type_contributor_id: 0,
    type_id: 0,
    type_commission_id: 0,
    commission_percent: '',
    name: '',
    business_name: '',
    street: '',
    city: '',
    state: '',
    postal_code: 0,
    first_name: '',
    father_last_name: '',
    mother_last_name: '',
    gender_id: 0,
    birthdate: '',
    email: '',
    phone: '',
    interbank_key: '',
    bank_name: '',
    account_owner_first_name: '',
    account_owner_last_name: '',
    cfdi_id: 0,
    billing_mail: '',
    rfc: '',
    pdfFile: '',
    id: 0,
  });
  const formEmpresa = useFormik({
    initialValues: initValuesDis,
    validationSchema: EmpresaSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      console.log('Values being submitted:', values);
      console.log('Formik errors:', formEmpresa.errors);
      let idEmpresaSocio = Number(values.id);

      setLoading(true);
      setTimeout(() => {
        updEmpresaSocio(values, idEmpresaSocio)
          .then((res) => {
            const {
              data: { message },
            } = res;
            SimpleAlert(message, 3000, 'success');
            formEmpresa.resetForm();
            setDocumentName('');
            setChangeTypeContributor(0);
            setLoading(false);
            handleClose(true);
          })
          .catch((err) => {
            let resMessageToast: string = '';
            const {
              data: { message, details = null },
            } = err.response;
            resMessageToast = `${message}`;
            if (details.length) {
              setStatus(`${details[0]?.message}`);
            }
            setLoading(false);
            setSubmitting(false);
            SimpleAlert(resMessageToast, 3000, 'error');
          });
      }, 1000);
    },
  });

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);
    formEmpresa.setFieldValue('phone', maskedPhoneNumber);
    formEmpresa.setFieldValue('phone', cleanPhoneNumber(maskedPhoneNumber));
  };

  const handleChangeType = (option: React.ChangeEvent<HTMLSelectElement>) => {
    const { value: id } = option.target;
    const code =
      option.target.options[option.target.selectedIndex].getAttribute('code');
    if (code === 'SOC') {
      formEmpresa.setFieldValue('name', '');
    }
    formEmpresa.setFieldValue('type.id', id);
    formEmpresa.setFieldValue('type.code', code);
  };

  const onOpenFile = () => {
    constancyRef?.current?.click();
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value;
    const valueReg = value.replace(/\d/g, '');
    const cleanInput = valueReg.replace(/^\s+|\s+(?=\s)/g, '');
    const inputLimpio = cleanInput.replace(/[^\wáéíóúüÁÉÍÓÚÜñÑ\s]/g, '');
    formEmpresa.setFieldValue(key, inputLimpio);
  };

  const changeUtilityValue = (values: any) => {
    const { floatValue } = values;
    let utilidad_porcentaje = floatValue || 0;
    formEmpresa.setFieldValue('commission_percent', utilidad_porcentaje);
  };

  useEffect(() => {
    setInitValuesDis({
      type_contributor_id: DataRegister?.id_type_person ?? 0,
      type_commission_id: DataRegister?.account?.type_commision_id ?? 0,
      type_id: DataRegister?.type_id ?? 0,
      commission_percent: DataRegister?.account?.commission_percent ?? 0,
      name: DataRegister?.name ?? '',
      business_name: DataRegister?.business_name ?? '',
      street: DataRegister?.street ?? '',
      city: DataRegister?.city ?? '',
      state: DataRegister?.state ?? '',
      postal_code: DataRegister?.postal_code
        ? DataRegister.postal_code.toString()
        : '',
      first_name: DataRegister?.user?.first_name ?? '',
      father_last_name: DataRegister?.user?.father_last_name ?? '',
      mother_last_name: DataRegister?.user?.mother_last_name ?? '',
      gender_id: DataRegister?.user?.gender_id ?? 0,
      birthdate: DataRegister?.user?.birthdate ?? '',
      email: DataRegister?.user?.email ?? '',
      phone: DataRegister?.user?.phone ?? '',
      interbank_key: '',
      bank_name: DataRegister?.bank_name ?? '',
      account_owner_first_name: DataRegister?.first_name ?? '',
      account_owner_last_name: DataRegister?.father_last_name ?? '',
      cfdi_id: DataRegister?.cfdi_id ?? 0,
      billing_mail: DataRegister?.email ?? '',
      rfc: DataRegister?.rfc ?? '',
      pdfFile: '',
      id: DataRegister?.id ?? 0,
    });
    setDocumentName(DataRegister?.constancy);
    setChangeTypeContributor(DataRegister?.id_type_person);
    setChangeTypeUser(DataRegister?.type_id);
    setDisInactivo(DataRegister?.active ? false : true);
  }, [DataRegister, show]);

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-xl"
      show={show}
      onHide={() => {
        setDocumentName('');
        formEmpresa.resetForm();
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              setDocumentName('');
              setChangeTypeContributor(0);
              formEmpresa.resetForm();
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Editar socio / empresa.</h1>
            <div className="text-muted fw-semibold fs-5">
              Modifica información de socio / empresa{' '}
            </div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={formEmpresa.handleSubmit}
          >
            {/* inicio socio/empresa */}
            <div className="row g-9 mb-5">
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className=" fw-bold fs-6 mb-2 w-auto required">
                    Tipo
                  </label>
                  {formEmpresa.touched.type_id && formEmpresa.errors.type_id && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formEmpresa.errors.type_id}</span>
                    </div>
                  )}
                </div>
                <select
                  disabled
                  className="form-select"
                  value={formEmpresa.values.type_id}
                  onChange={(e) => {
                    let value = Number(e.target.value);
                    formEmpresa.setFieldValue('type_id', value);
                    setChangeTypeUser(value);
                  }}
                >
                  {types.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              {changeTypeContributor === 1 || changeTypeUser === 13 ? (
                <div className="col-md-3">
                  <div className="w-100 d-flex">
                    <label className="required fw-bold fs-6 mb-2 w-auto">
                      Tipo de contribuyente
                    </label>
                    {formEmpresa.touched.type_contributor_id &&
                      formEmpresa.errors.type_contributor_id && (
                        <div className="fv-plugins-message-container text-danger w-auto">
                          <span role="alert">
                            {formEmpresa.errors.type_contributor_id}
                          </span>
                        </div>
                      )}
                  </div>
                  <select
                    disabled
                    className="form-select"
                    value={formEmpresa.values.type_contributor_id}
                    // onChange={(e) => {
                    //   formEmpresa.setFieldValue(
                    //     'type_contributor_id',
                    //     Number(e.target.value)
                    //   );
                    //   setChangeTypeContributor(parseInt(e.target.value));
                    // }}
                  >
                    <option value={0}>Seleccione</option>
                    {tipo_persona.map((item, index) => (
                      <option key={index} value={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
              ) : null}

              {changeTypeContributor === 1 && (
                <>
                  <div className="col-md-3">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Comisión
                      </label>
                      {formEmpresa.touched.type_commission_id &&
                        formEmpresa.errors.type_commission_id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formEmpresa.errors.type_commission_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      disabled
                      className="form-select"
                      {...formEmpresa.getFieldProps('type_commission_id')}
                    >
                      <option value={0}>Seleccione</option>
                      {tipo_comision.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-3 fv-row fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-40">
                        Porcentaje de comisión
                      </label>
                      {formEmpresa.touched.commission_percent &&
                        formEmpresa.errors.commission_percent && (
                          <div className="fv-plugins-message-container text-danger w-60 ms-5">
                            <span role="alert">
                              {formEmpresa.errors.commission_percent}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      disabled
                      type="text"
                      name="commission_percent"
                      className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                      value={formEmpresa.values.commission_percent}
                      decimalScale={0}
                      allowLeadingZeros
                      fixedDecimalScale
                      placeholder="Porcentaje de comisión"
                      suffix={' %'}
                      thousandSeparator={''}
                      allowNegative={false}
                      isAllowed={(values: any) => {
                        const { floatValue } = values;
                        let allow =
                          floatValue <= 100 || floatValue === undefined;
                        return allow;
                      }}
                      onValueChange={changeUtilityValue}
                    />
                  </div>
                </>
              )}
              {changeTypeContributor === 2 && (
                <>
                  <div className="col-md-3 fv-row fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className={`fw-bold fs-6 mb-2 w-auto required`}>
                        Nombre de la empresa
                      </label>
                      {formEmpresa.touched.name && formEmpresa.errors.name && (
                        <div className="fv-plugins-message-container text-danger w-100 ms-5">
                          <span role="alert">{formEmpresa.errors.name}</span>
                        </div>
                      )}
                    </div>
                    <input
                      disabled={disInactivo}
                      placeholder="Nombre del distribuidor"
                      {...formEmpresa.getFieldProps('name')}
                      className="form-control"
                      type="text"
                      name="name"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (
                          !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                          ![
                            'Backspace',
                            'Tab',
                            'ArrowLeft',
                            'ArrowRight',
                            'Delete',
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Razón social
                      </label>
                      {formEmpresa.touched.business_name &&
                        formEmpresa.errors.business_name && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formEmpresa.errors.business_name}
                            </span>
                          </div>
                        )}
                    </div>
                    <input
                      disabled={disInactivo}
                      placeholder="Razón social"
                      {...formEmpresa.getFieldProps('business_name')}
                      className="form-control"
                      type="text"
                      name="business_name"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (
                          !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                          ![
                            'Backspace',
                            'Tab',
                            'ArrowLeft',
                            'ArrowRight',
                            'Delete',
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>
                  <div className="col-md-3">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Comisión
                      </label>
                      {formEmpresa.touched.type_commission_id &&
                        formEmpresa.errors.type_commission_id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formEmpresa.errors.type_commission_id}
                            </span>
                          </div>
                        )}
                    </div>
                    <select
                      disabled
                      className="form-select"
                      {...formEmpresa.getFieldProps('type_commission_id')}
                    >
                      <option value={0}>Seleccione</option>
                      {tipo_comision.map((item, index) => (
                        <option key={index} value={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="col-md-3">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Calle y Número
                      </label>
                      {formEmpresa.touched.street && formEmpresa.errors.street && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formEmpresa.errors.street}</span>
                        </div>
                      )}
                    </div>
                    <input
                      disabled={disInactivo}
                      placeholder="Calle y Número"
                      {...formEmpresa.getFieldProps('street')}
                      className="form-control"
                      type="text"
                      name="street"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (
                          !/^[a-zA-ZñÑáéíóúÁÉÍÓÚüÜ0-9 ]$/.test(e.key) &&
                          ![
                            'Backspace',
                            'Tab',
                            'ArrowLeft',
                            'ArrowRight',
                            'Delete',
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Ciudad
                      </label>
                      {formEmpresa.touched.city && formEmpresa.errors.city && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formEmpresa.errors.city}</span>
                        </div>
                      )}
                    </div>
                    <input
                      disabled={disInactivo}
                      placeholder="Ciudad"
                      {...formEmpresa.getFieldProps('city')}
                      className="form-control"
                      type="text"
                      name="city"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (
                          !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                          ![
                            'Backspace',
                            'Tab',
                            'ArrowLeft',
                            'ArrowRight',
                            'Delete',
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div className="col-md-3">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-auto">
                        Estado
                      </label>
                      {formEmpresa.touched.state && formEmpresa.errors.state && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">{formEmpresa.errors.state}</span>
                        </div>
                      )}
                    </div>
                    <input
                      disabled={disInactivo}
                      placeholder="Estado"
                      {...formEmpresa.getFieldProps('state')}
                      className="form-control"
                      type="text"
                      name="state"
                      autoComplete="off"
                      onKeyDown={(e) => {
                        if (
                          !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                          ![
                            'Backspace',
                            'Tab',
                            'ArrowLeft',
                            'ArrowRight',
                            'Delete',
                          ].includes(e.key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                  </div>

                  <div className="col-md-3 fv-row fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-40">
                        Porcentaje de comisión
                      </label>
                      {formEmpresa.touched.commission_percent &&
                        formEmpresa.errors.commission_percent && (
                          <div className="fv-plugins-message-container text-danger w-60 ms-5">
                            <span role="alert">
                              {formEmpresa.errors.commission_percent}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      disabled
                      type="text"
                      name="commission_percent"
                      className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                      value={formEmpresa.values.commission_percent}
                      decimalScale={0}
                      allowLeadingZeros
                      fixedDecimalScale
                      placeholder="Porcentaje de comisión"
                      suffix={' %'}
                      thousandSeparator={''}
                      allowNegative={false}
                      isAllowed={(values: any) => {
                        const { floatValue } = values;
                        let allow =
                          floatValue <= 100 || floatValue === undefined;
                        return allow;
                      }}
                      onValueChange={changeUtilityValue}
                    />
                  </div>
                  <div className="col-md-3 fv-row fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fw-bold fs-6 mb-2 w-40">
                        Código postal
                      </label>
                      {formEmpresa.touched.postal_code &&
                        formEmpresa.errors.postal_code && (
                          <div className="fv-plugins-message-container text-danger w-60 ms-5">
                            <span role="alert">
                              {formEmpresa.errors.postal_code}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      disabled={disInactivo}
                      name="postal_code"
                      className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                      value={formEmpresa.values.postal_code}
                      decimalScale={0}
                      allowLeadingZeros
                      maxLength={5}
                      minLength={5}
                      fixedDecimalScale
                      pattern="[0-9]*"
                      placeholder="Código postal"
                      prefix=""
                      thousandSeparator=""
                      onValueChange={(values) => {
                        const { value } = values;
                        formEmpresa.setFieldValue(
                          'postal_code',
                          value ? value : ''
                        );
                      }}
                    />
                  </div>
                </>
              )}
            </div>
            {/* fin socio/empresa */}
            <div className="text-gray-700 mt-7 fs-4 fw-bold">
              Datos del encargado
            </div>
            <div className="separator my-5"></div>
            <div className="row g-9 mb-5">
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Nombre
                  </label>
                  {formEmpresa.touched.first_name &&
                    formEmpresa.errors.first_name && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">
                          {formEmpresa.errors.first_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  disabled={disInactivo}
                  placeholder="Nombre del encargado"
                  {...formEmpresa.getFieldProps('first_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="first_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'first_name');
                  }}
                  onKeyDown={(e) => {
                    if (
                      !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                      ![
                        'Backspace',
                        'Tab',
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                      ].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Apellido Paterno
                  </label>
                  {formEmpresa.touched.father_last_name &&
                    formEmpresa.errors.father_last_name && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">
                          {formEmpresa.errors.father_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  disabled={disInactivo}
                  placeholder="Apellido Paterno"
                  {...formEmpresa.getFieldProps('father_last_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="father_last_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'father_last_name');
                  }}
                  onKeyDown={(e) => {
                    if (
                      !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                      ![
                        'Backspace',
                        'Tab',
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                      ].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Apellido Materno
                  </label>
                  {formEmpresa.touched.mother_last_name &&
                    formEmpresa.errors.mother_last_name && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">
                          {formEmpresa.errors.mother_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  disabled={disInactivo}
                  placeholder="Apellido Materno"
                  {...formEmpresa.getFieldProps('mother_last_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="mother_last_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'mother_last_name');
                  }}
                  onKeyDown={(e) => {
                    if (
                      !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                      ![
                        'Backspace',
                        'Tab',
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                      ].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="row g-9 mb-5">
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Género
                  </label>
                  {formEmpresa.touched.gender_id &&
                    formEmpresa.errors.gender_id && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">{formEmpresa.errors.gender_id}</span>
                      </div>
                    )}
                </div>
                <select
                  disabled={disInactivo}
                  className="form-select"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...formEmpresa.getFieldProps('gender_id')}
                >
                  <option value={0}>Seleccione</option>
                  {genders.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Fecha de nacimiento
                  </label>
                  {formEmpresa.touched.birthdate &&
                    formEmpresa.errors.birthdate && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">{formEmpresa.errors.birthdate}</span>
                      </div>
                    )}
                </div>
                <input
                  disabled={disInactivo}
                  type="date"
                  className="form-control"
                  value={
                    formEmpresa.values.birthdate
                      ? formEmpresa.values.birthdate.split('T')[0]
                      : ''
                  }
                  max={maxDate}
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      formEmpresa.setFieldValue('birthdate', fecha);
                    }
                  }}
                ></input>
              </div>
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Correo
                  </label>
                  {formEmpresa.touched.email && formEmpresa.errors.email && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                      <span role="alert">{formEmpresa.errors.email}</span>
                    </div>
                  )}
                </div>
                <input
                  disabled={disInactivo}
                  placeholder="Correo"
                  {...formEmpresa.getFieldProps('email')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="email"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-3 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Teléfono
                  </label>
                  {formEmpresa.touched.phone && formEmpresa.errors.phone && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                      <span role="alert">{formEmpresa.errors.phone}</span>
                    </div>
                  )}
                </div>
                <input
                  disabled={disInactivo}
                  className="form-control mb-3"
                  value={formEmpresa.values.phone}
                  placeholder="Teléfono"
                  prefix=""
                  name="phone"
                  onChange={handlePhoneChange}
                />
              </div>
            </div>

            <div className="text-gray-700 mt-7 fs-4 fw-bold">
              Datos bancarios
            </div>
            <div className="separator my-5"></div>
            <div className="row g-9 mb-5">
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Nombre
                  </label>
                  {formEmpresa.touched.account_owner_first_name &&
                    formEmpresa.errors.account_owner_first_name && (
                      <div className="fv-plugins-message-container text-danger w-100 ms-5">
                        <span role="alert">
                          {formEmpresa.errors.account_owner_first_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  disabled={disInactivo}
                  placeholder="Nombre del encargado"
                  {...formEmpresa.getFieldProps('account_owner_first_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="account_owner_first_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'account_owner_first_name');
                  }}
                  onKeyDown={(e) => {
                    if (
                      !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                      ![
                        'Backspace',
                        'Tab',
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                      ].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Apellido Paterno
                  </label>
                  {formEmpresa.touched.account_owner_last_name &&
                    formEmpresa.errors.account_owner_last_name && (
                      <div className="fv-plugins-message-container text-danger w-50 ms-5">
                        <span role="alert">
                          {formEmpresa.errors.account_owner_last_name}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  disabled={disInactivo}
                  placeholder="Apellido Paterno"
                  {...formEmpresa.getFieldProps('account_owner_last_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="account_owner_last_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'account_owner_last_name');
                  }}
                  onKeyDown={(e) => {
                    if (
                      !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                      ![
                        'Backspace',
                        'Tab',
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                      ].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    CLABE Interbancaria
                  </label>
                  {formEmpresa.touched.interbank_key &&
                    formEmpresa.errors.interbank_key && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">
                          {formEmpresa.errors.interbank_key}
                        </span>
                      </div>
                    )}
                </div>
                <NumericFormat
                  disabled={disInactivo}
                  name="interbank_key"
                  className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                  value={formEmpresa.values.interbank_key}
                  decimalScale={0}
                  allowLeadingZeros
                  valueIsNumericString
                  maxLength={18}
                  fixedDecimalScale
                  placeholder="******************"
                  prefix=""
                  thousandSeparator=""
                  onValueChange={(values) => {
                    const { value } = values;
                    formEmpresa.setFieldValue(
                      'interbank_key',
                      value ? value : ''
                    );
                  }}
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className=" fw-bold fs-6 mb-2 w-auto required">
                    Banco
                  </label>
                  {formEmpresa.touched.bank_name &&
                    formEmpresa.errors.bank_name && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">{formEmpresa.errors.bank_name}</span>
                      </div>
                    )}
                </div>
                <input
                  disabled={disInactivo}
                  placeholder="Banco"
                  {...formEmpresa.getFieldProps('bank_name')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="bank_name"
                  autoComplete="off"
                  onChange={(e: any) => {
                    handleChangeInput(e, 'bank_name');
                  }}
                  onKeyDown={(e) => {
                    if (
                      !/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]$/.test(e.key) &&
                      ![
                        'Backspace',
                        'Tab',
                        'ArrowLeft',
                        'ArrowRight',
                        'Delete',
                      ].includes(e.key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            </div>
            <div className="text-gray-700 mt-7 fs-4 fw-bold">
              Datos de facturación
            </div>
            <div className="separator my-5"></div>
            <div className="row g-9 mb-5">
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-auto">
                    Uso de CFDI
                  </label>
                  {formEmpresa.touched.cfdi_id && formEmpresa.errors.cfdi_id && (
                    <div className="fv-plugins-message-container text-danger w-100 ms-5">
                      <span role="alert">{formEmpresa.errors.cfdi_id}</span>
                    </div>
                  )}
                </div>
                <select
                  disabled={disInactivo}
                  className="form-select"
                  data-kt-select2="true"
                  data-placeholder="Select option"
                  data-allow-clear="true"
                  {...formEmpresa.getFieldProps('cfdi_id')}
                >
                  <option value={0}>Seleccione</option>
                  {cfdi.map((item, index) => (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fw-bold fs-6 mb-2 w-40">
                    Correo
                  </label>
                  {formEmpresa.touched.billing_mail &&
                    formEmpresa.errors.billing_mail && (
                      <div className="fv-plugins-message-container text-danger w-60 ms-5">
                        <span role="alert">
                          {formEmpresa.errors.billing_mail}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  disabled={disInactivo}
                  placeholder="Correo"
                  {...formEmpresa.getFieldProps('billing_mail')}
                  className="form-control mb-3 mb-lg-0"
                  type="text"
                  name="billing_mail"
                  autoComplete="off"
                />
              </div>
              <div className="col-md-4 fv-row fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className=" fw-bold fs-6 mb-2 w-auto required">
                    RFC
                  </label>
                  {formEmpresa.touched.rfc && formEmpresa.errors.rfc && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                      <span role="alert">{formEmpresa.errors.rfc}</span>
                    </div>
                  )}
                </div>
                <input
                  disabled={disInactivo}
                  placeholder="RFC"
                  {...formEmpresa.getFieldProps('rfc')}
                  className="form-control mb-3 mb-lg-0"
                  maxLength={13}
                  type="text"
                  name="rfc"
                  autoComplete="off"
                />
              </div>
            </div>

            <div className="row g-9">
              <div className="col-md-12 fv-row fv-plugins-icon-container">
                <div className="d-flex justify-content-center text-center p-2">
                  <a
                    href="#/"
                    className="text-gray-800 text-hover-primary d-flex flex-column"
                  >
                    <div className="symbol symbol-50px mb-6">
                      <img
                        src={
                          formEmpresa.values.pdfFile
                            ? toAbsoluteUrl('/media/svg/files/pdf.svg')
                            : toAbsoluteUrl('/media/svg/files/upload.svg')
                        }
                        className="image-input-wrapper"
                        onClick={onOpenFile}
                        crossOrigin="anonymous"
                        alt="INE"
                      />
                    </div>

                    <div className="d-flex">
                      <div
                        className="required fs-5 mb-2 flex-column"
                        onClick={onOpenFile}
                      >
                        Constancia de situación fiscal
                      </div>
                      <div className="fs-5 mb-2 flex-column ms-5">
                        {formEmpresa.touched.pdfFile &&
                          formEmpresa.errors.pdfFile && (
                            <div className="fv-plugins-message-container text-danger w-100">
                              <span role="alert">
                                {formEmpresa.errors.pdfFile}
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                    <label className="min-h-30px text-success">
                      {documentName}
                    </label>

                    <input
                      disabled={disInactivo}
                      style={{ display: 'none' }}
                      ref={constancyRef}
                      type="file"
                      name="pdfFile"
                      accept=".pdf"
                      onChange={(event) => {
                        if (event.currentTarget.files) {
                          let files = event.currentTarget.files;
                          if (files.length > 0) {
                            formEmpresa.setFieldValue('pdfFile', files[0]);
                            setDocumentName(files[0].name);
                          }
                        }
                      }}
                    />
                  </a>
                </div>
              </div>
            </div>

            <div className="text-center pt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  setDocumentName('');
                  setChangeTypeContributor(0);
                  formEmpresa.resetForm();
                  handleClose(false);
                }}
              >
                Cancelar
              </button>
              {!disInactivo ? (
                <button
                  type="submit"
                  className="btn btn-primary"
                  data-kt-users-modal-action="submit"
                  disabled={loading}
                >
                  {!loading && <span className="indicator-label">Guardar</span>}
                  {loading && (
                    <span
                      className="indicator-progress"
                      style={{ display: 'block' }}
                    >
                      Espere por favor...
                      <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                  )}
                </button>
              ) : null}
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
export { UpdateModal };
