import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import {
  KTSVG,
  SimpleAlert,
  formatDateCompleta,
  formatPrice,
  maskPhoneNumber,
} from '../../../../_mexablock/helpers';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import { AddNumIccid } from '../../../service/pre_registro/preregistro';
import { PreregistroModel } from '../models/CuentaModel';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  infoEnvios: PreregistroModel;
};
const EnvioSchema = Yup.object().shape({
  iccid: Yup.string()
    .strict(true)
    .typeError('ICCID es requerido.')
    .matches(/^\d+$/, 'El ICCID debe contener solo números')
    .min(19, 'El ICCID debe contener 19 caracteres.')
    .max(19, 'El ICCID debe contener 19 caracteres.')
    .required('ICCID es requerido.'),
});

const DetailsModal: React.FC<Props> = ({ show, handleClose, infoEnvios }) => {
  const [loading, setLoading] = useState(false);
  const formEnvio = useFormik({
    initialValues: infoEnvios,
    validationSchema: EnvioSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        AddNumIccid(values)
          .then((res) => {
            const { message, doc } = res;
            if (doc?.data) {
              SimpleAlert(message, 3000, 'success');
              formEnvio.resetForm();
            } else {
              SimpleAlert(
                'Ocurrió un error inesperado al actualizar el ICCID.',
                3000,
                'error'
              );
            }
            formEnvio.resetForm();
            setLoading(false);
            handleClose(true);
          })
          .catch((err) => {
            let resMessageToast = 'Ocurrió un problema con la solicitud';
            if (err.response && err.response.data) {
              const { message, details = null } = err.response.data;
              resMessageToast = details?.length ? details[0].message : message;
            } else {
              console.error('Error sin respuesta:', err);
            }
            setLoading(false);
            setSubmitting(false);
            SimpleAlert(resMessageToast, 3000, 'error');
          });
      }, 1000);
    },
  });

  const statusEnvio = (asigEstatus: any) => {
    switch (asigEstatus) {
      case 1:
        return (
          <span className="fw-normal badge badge-light-warning">Pendiente</span>
        );
      case 2:
        return (
          <span className="fw-normal badge badge-light-primary">
            En proceso
          </span>
        );
      case 3:
        return (
          <span className="fw-normal badge badge-light-success">Exitoso</span>
        );
      case 8:
        return (
          <span className="fw-normal badge badge-light-success">Portada</span>
        );
      case 7:
        return (
          <span className="fw-normal badge badge-light-success">Activada</span>
        );
      case 4:
        return (
          <span className="fw-normal badge badge-light-danger">Error</span>
        );
    }
  };

  if (!show || !infoEnvios) return null;

  return (
    <Modal
      id="kt_modal_create"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-md"
      show={show}
      //   onHide={() => {
      //     formCallCenter.resetForm();
      //     handleClose(false);
      //   }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 d-flex justify-content-center">
          <div className="row w-100 mb-10 ">
            <div className="col-8 m-0">
              <h1>Detalles de pre-registro</h1>
            </div>
            <div className="col-2 d-flex align-items-center justify-content-start">
              {statusEnvio(formEnvio.values.activation_status_id)}
            </div>
            <div className="col-2">
              <div
                className="btn btn-sm btn-icon btn-active-color-primary ms-3 justify-content-end"
                data-bs-dismiss="modal"
                onClick={() => {
                  handleClose(true);
                }}
              >
                <KTSVG
                  className="svg-icon-2"
                  path="/media/icons/duotune/arrows/arr061.svg"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="row mb-5">
            <div>Identificación</div>
            <div className="col text-muted">
              <div>ID de Pre-registro</div>
            </div>
            <div className="col text-end">
              <div>{infoEnvios.id}</div>
            </div>
          </div>
          <div className="row mb-5">
            <div>Datos del Cliente</div>
            <div className="col text-muted">
              <div>Nombre completo</div>
              <div>Correo electrónico</div>
              <div>Genero</div>
              <div>Fecha de nacimiento</div>
            </div>
            <div className="col text-end">
              <div>
                {formEnvio.values.user.first_name}{' '}
                {formEnvio.values.user.father_last_name}
              </div>
              <div>{formEnvio.values.user.email}</div>
              <div>
                {formEnvio.values.user.gender
                  ? formEnvio.values.user.gender.name
                  : 'Sin genero'}
              </div>
              <div>
                {formEnvio.values.user.birthdate
                  ? formatDateCompleta(formEnvio.values.user.birthdate)
                  : 'Sin fecha'}
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div>Datos de oferta</div>
            <div className="col text-muted">
              <div>Paquete </div>
              <div>Precio</div>
              <div>Beneficios</div>
            </div>
            <div className="col text-end">
              <div>{formEnvio.values.offer.commercial_name}</div>
              <div>
                {formatPrice(
                  Number(formEnvio.values.offer.price),
                  'es-MX',
                  'MXN',
                  2,
                  2
                )}
              </div>
              <div>
                {formEnvio.values.offer.share_internet ? 'Hotspot' : null}
              </div>
              <div>{formEnvio.values.offer.sms} mensajes</div>
              <div>{formEnvio.values.offer.minutes} minutos</div>
              <div>{formEnvio.values.offer.gb} GB</div>
            </div>
          </div>

          <div className="row mb-5">
            <div>Operación</div>
            <div className="col text-muted">
              <div>
                {formEnvio.values.is_portability
                  ? formEnvio.values.activation?.portability?.phone_number
                    ? 'Portabilidad'
                    : 'Por asignar'
                  : formEnvio.values.activation?.msisdn
                  ? 'Activación'
                  : 'Por asignar'}
              </div>
            </div>
            <div className="col text-end">
              <div>
                {formEnvio.values.is_portability
                  ? formEnvio.values.activation?.portability?.phone_number
                    ? maskPhoneNumber(
                        formEnvio.values.activation.portability.phone_number
                      )
                    : 'Por asignar'
                  : formEnvio.values.activation?.msisdn
                  ? maskPhoneNumber(
                      formEnvio.values.activation.msisdn.toString()
                    )
                  : 'Por asignar'}
              </div>
            </div>
          </div>
          <div className="separator my-10"></div>

          {infoEnvios.iccid ? (
            <div className="row mb-5">
              <div>ICCID</div>
              <div className="col text-muted">
                <div>{infoEnvios.iccid}</div>
              </div>
            </div>
          ) : (
            <form
              id="kt_modal_generic_form"
              className="form"
              action="#"
              onSubmit={formEnvio.handleSubmit}
            >
              <div className="row">
                <div className="w-100">
                  <label className="required fw-bold mb-1 w-auto">ICCID</label>
                </div>
                <div className="col-7">
                  <NumericFormat
                    name="trackingNumber"
                    type="text"
                    autoComplete="off"
                    className="form-control mb-3"
                    value={formEnvio.values.iccid || ''}
                    maxLength={19}
                    onValueChange={(values) => {
                      formEnvio.setFieldValue('iccid', values.value || '');
                    }}
                  />
                  {formEnvio.touched.iccid && formEnvio.errors.iccid && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                      <span role="alert">{formEnvio.errors.iccid}</span>
                    </div>
                  )}
                </div>
                <div className="col-5 ">
                  <button
                    type="submit"
                    className="btn btn-primary me-3"
                    data-kt-users-modal-action="submit"
                    disabled={loading}
                  >
                    {!loading && (
                      <span className="indicator-label"> Asignar ICCID</span>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}

          <div className="text-center pt-10">
            <button
              className="btn btn-primary me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                handleClose(true);
                formEnvio.resetForm();
              }}
            >
              Cerrar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { DetailsModal };
