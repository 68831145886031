import { useState, useEffect } from 'react';
import {
  getAll,
  getOneSocios,
} from '../../../service/distribuidores/Distribuidores';
import {
  DistribuidorModel,
  TipoDistribuidorModel,
} from '../models/DistribuidorModel';
import { useHistory } from 'react-router-dom';

export const useGetAll = (realoadGrid: number, filters: any) => {
  const [data, setData] = useState<DistribuidorModel[]>([]);
  const [loadingRequest, setLoadingRequest] = useState(0);
  const history = useHistory();
  useEffect(() => {
    const fetchPost = async () => {
      setLoadingRequest(0);
      const res = await getAll(filters)
        .then()
        .catch((error) => {
          history.push('/error/500');
        });
      if (res) {
        setData(res.rows);
        setLoadingRequest(1);
      }
    };
    if (realoadGrid) {
      fetchPost();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [realoadGrid]);
  return { data, loadingRequest };
};

export const useGetOne = () => {
  const currentDate = new Date();
  const fechaMaxima = new Date(currentDate);
  fechaMaxima.setFullYear(currentDate.getFullYear() - 18);

  const initModel = {
    type_contributor_id: 0,
    // Commission
    type_commission_id: 0,
    commission_percent: '',
    // persona moral
    name: '',
    business_name: '',
    street: '',
    city: '',
    state: '',
    postal_code: 0,
    // Person
    first_name: '',
    father_last_name: '',
    mother_last_name: '',
    gender_id: 0,
    birthdate: '',
    email: '',
    phone: '',
    // Distributor
    interbank_key: '',
    bank_name: '',
    account_owner_first_name: '',
    account_owner_last_name: '',
    // facturación
    cfdi_id: 0,
    billing_mail: '',
    rfc: '',
    pdfFile: '',
  };
  const [initValues] = useState<TipoDistribuidorModel>(initModel);

  return { initValues };
};

export const useGetInfoDistribuidor = (dataDis: any = {}) => {
  console.log('informacion dentro del hook: ', dataDis);
  const initModel = {
    type_contributor_id: dataDis?.id_type_person ?? 0,
    type_commission_id: dataDis?.account?.type_commision_id ?? 0,
    commission_percent: dataDis?.account?.commission_percent ?? 0,
    name: dataDis?.name ?? '',
    business_name: dataDis?.business_name ?? '',
    street: dataDis?.street ?? '',
    city: dataDis?.city ?? '',
    state: dataDis?.state ?? '',
    postal_code: dataDis?.postal_code ?? '',
    first_name: dataDis?.user?.first_name ?? '',
    father_last_name: dataDis?.user?.father_last_name ?? '',
    mother_last_name: dataDis?.user?.mother_last_name ?? '',
    gender_id: dataDis?.user?.gender_id ?? 0,
    birthdate: dataDis?.user?.birthdate ?? '',
    email: dataDis?.user?.email ?? '',
    phone: dataDis?.phone ?? '',
    interbank_key: '',
    bank_name: dataDis?.bank_name ?? '',
    account_owner_first_name: dataDis?.first_name ?? '',
    account_owner_last_name: dataDis?.father_last_name ?? '',
    cfdi_id: dataDis?.cfdi_id ?? 0,
    billing_mail: dataDis?.email ?? '',
    rfc: dataDis?.rfc ?? '',
    pdfFile: '',
  };

  const [initValuesDis] = useState<TipoDistribuidorModel>(initModel);

  return { initValuesDis };
};
