import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import {
  KTSVG,
  maskPhoneNumber,
  cleanPhoneNumber,
  SimpleAlert,
} from '../../../../../_mexablock/helpers';
// import { useGetOne } from '../hooks/PortabilidadHook';
import { sendPortability } from '../../../../service/operacion/Portabilidad';

type Props = {
  id: number;
  show: boolean;
  handleClose: (updateData: boolean) => void;
};
const PortabilitySchema = Yup.object().shape({
  phoneFormat: Yup.string()
    .required('Requerido.')
    .min(10, 'Formato de teléfono inválido'),
  nip: Yup.string()
    .matches(/^\d{4}$/, 'NIP debe tener 4 dígitos.')
    .required('Requerido.'),
});

let maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 1);

const PortabilitySendModal: React.FC<Props> = ({ id, show, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const initValues = {
    id: id,
    phone: '',
    phoneFormat: '',
    nip: '',
    fecha_portabilidad: maxDate.toISOString().split('T')[0],
  };
  //   const { initValues } = useGetOne(id);
  const PortabilityForm = useFormik({
    initialValues: initValues,
    validationSchema: PortabilitySchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      setLoading(true);
      setTimeout(() => {
        if (values.id > 0) {
          sendPortability(values)
            .then((res) => {
              const {
                data: { message },
              } = res;
              SimpleAlert(message, 3000, 'success');
              PortabilityForm.resetForm();
              setLoading(false);
              handleClose(true);
            })
            .catch((err) => {
              let resMessageToast: string = '';
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
        }
      }, 1000);
    },
  });

  const getNextAvailableDate = (): string => {
    const today = new Date();
    let nextDay = new Date(today);
    nextDay.setDate(today.getDate() + 1);
    if (nextDay.getDay() === 0) {
      nextDay.setDate(nextDay.getDate() + 1);
    }
    return nextDay.toISOString().split('T')[0];
  };

  const handlePhoneChange = (values: any) => {
    const value = values.target.value.toString();
    const maskedPhoneNumber = maskPhoneNumber(value);

    PortabilityForm.setFieldValue('phone', maskedPhoneNumber);
    PortabilityForm.setFieldValue(
      'phoneFormat',
      cleanPhoneNumber(maskedPhoneNumber)
    );
  };

  return (
    <Modal
      id="kt_modal_portability"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered modal-lg"
      show={show}
      onHide={() => {
        handleClose(false);
        setTimeout(() => {
          PortabilityForm.resetForm();
        }, 1000);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleClose(false);
              setTimeout(() => {
                PortabilityForm.resetForm();
              }, 1000);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body scroll-y mx-5 pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Enviar a Portar</h1>
            <div className="text-muted fw-semibold fs-5">
              Favor ingrese los siguientes datos para la portabilidad.
            </div>
          </div>
          <form
            id="kt_modal_generic_form"
            className="form"
            action="#"
            onSubmit={PortabilityForm.handleSubmit}
          >
            <div className="w-100 me-20 h-auto">
              <div className="fv-row mb-7 fv-plugins-icon-container">
                <div className="w-100 d-flex">
                  <label className="required fs-6 fw-semibold form-label mb-2">
                    Número a portar
                  </label>
                  {PortabilityForm.touched.phoneFormat &&
                    PortabilityForm.errors.phoneFormat && (
                      <div className="fv-plugins-message-container text-danger w-auto ms-5">
                        <span role="alert">
                          {PortabilityForm.errors.phoneFormat}
                        </span>
                      </div>
                    )}
                </div>
                <input
                  className="form-control mb-3"
                  value={PortabilityForm.values.phone}
                  placeholder="Número a portar"
                  prefix=""
                  onChange={handlePhoneChange}
                />
                <div className="form-text">
                  Este número será utilizado para iniciar el proceso de
                  portabilidad y transferir tu línea. Asegúrate de que el número
                  esté correctamente escrito y que no contenga errores, ya que
                  este dato es esencial para completar la solicitud de forma
                  exitosa.
                </div>
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label
                    className="required fs-6 fw-semibold form-label mb-2"
                    htmlFor="nip"
                  >
                    NIP
                  </label>
                  {PortabilityForm.touched.nip && PortabilityForm.errors.nip && (
                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                      <span role="alert">{PortabilityForm.errors.nip}</span>
                    </div>
                  )}
                </div>

                <NumericFormat
                  id="nip"
                  className="form-control mb-3"
                  {...PortabilityForm.getFieldProps('nip')}
                  decimalScale={0}
                  fixedDecimalScale
                  placeholder="NIP de portabilidad"
                  isAllowed={(values: any) => {
                    const { floatValue = 0 } = values;
                    return floatValue <= 9999;
                  }}
                  onValueChange={(values: any) => {
                    const { floatValue } = values;
                    PortabilityForm.setFieldValue('nip', floatValue);
                  }}
                />
                <div className="form-text">
                  Para obtener su <span className="fw-bold"> NIP</span> de
                  portabilidad, envie un mensaje al{' '}
                  <span className="fw-bold"> 051</span> con la palabra{' '}
                  <span className="fw-bold"> NIP</span> ó marque al{' '}
                  <span className="fw-bold"> 051</span> y solicite su{' '}
                  <span className="fw-bold"> NIP</span>
                </div>
              </div>
              <div className="fv-row mb-7">
                <div className="w-100 d-flex">
                  <label
                    className="required fs-6 fw-semibold form-label mb-2"
                    htmlFor="fecha_portabilidad"
                  >
                    Fecha portabilidad
                  </label>
                </div>
                <input
                  id="fecha_portabilidad"
                  type="date"
                  disabled
                  onClick={(e) => e.preventDefault()}
                  onKeyDown={(e) => e.preventDefault()}
                  className="form-control form-control-solid"
                  value={getNextAvailableDate()}
                  min={maxDate.toISOString().split('T')[0]}
                  onChange={(e) => {
                    if (e.target.value) {
                      const fecha = new Date(e.target.value)
                        .toISOString()
                        .split('T')[0];
                      PortabilityForm.setFieldValue(
                        'fecha_portabilidad',
                        fecha
                      );
                    }
                  }}
                ></input>
                <div className="form-text">
                  Recuerda que la portabilidad no se realizará en domingos ni
                  días festivos. Asimismo, ten en cuenta que el proceso se
                  completará en un plazo máximo de 24 horas.
                </div>
              </div>
            </div>
            <div className="text-center pt-10">
              <button
                type="reset"
                className="btn btn-light me-3"
                data-kt-users-modal-action="cancel"
                onClick={() => {
                  handleClose(false);
                  setTimeout(() => {
                    PortabilityForm.resetForm();
                  }, 1000);
                }}
              >
                Cancelar
              </button>
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={loading}
              >
                {!loading && <span className="indicator-label">Enviar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export { PortabilitySendModal };
