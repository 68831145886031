/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { createPass } from '../../service/users/Usuarios';
// import * as auth from '../redux/AuthRedux';

// import { login } from '../redux/AuthCRUD';
import { toast } from 'react-toastify';
import { SimpleAlert, toAbsoluteUrl } from '../../../_mexablock/helpers';
interface Params {
  token?: string;
}

export function CreatePass() {
  const { token } = useParams<Params>();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [visivility, setVisivility] = useState(false);
  const [visivilityFirst, setVisivilityFirst] = useState(false);
  const validateVisibility = () => {
    setVisivility(!visivility);
  };
  const validateVisibilityFirst = () => {
    setVisivilityFirst(!visivilityFirst);
  };

  // const confirmPasswordSchema = Yup.object().shape({
  //     password: Yup.string()
  //         .matches(
  //             /^(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,20}$/,
  //             'Formato de contraseña incorrecta'
  //         )
  //         .required('Contraseña es requerido.'),
  //     confirmPassword: Yup.string()
  //         .matches(
  //             /^(?=.*[A-Z])(?=.*[@#$%^&-+=()])(?=.*[0-9])(?=.*[a-z])(?=\S+$).{8,20}$/,
  //             'Formato de contraseña incorrecta'
  //         )
  //         .required('Contraseña es requerido.')
  //         .oneOf([Yup.ref('password'), null], 'La contraseña no coincide'),
  // });

  const confirmPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('La contraseña es obligatoria.')
      .min(8, 'La contraseña debe tener al menos 8 caracteres.')
      .max(20, 'La contraseña no debe superar los 20 caracteres.')
      .matches(/[A-Z]/, 'Debe contener al menos una letra mayúscula.')
      .matches(/[a-z]/, 'Debe contener al menos una letra minúscula.')
      .matches(/[0-9]/, 'Debe incluir al menos un número.')
      .matches(
        /[@#$%^&\-+=()]/,
        'Debe incluir al menos un carácter especial (@, #, $, %, ^, &, -, +, =, (, )).'
      )
      .matches(/^\S*$/, 'No debe contener espacios en blanco.'),

    confirmPassword: Yup.string()
      .required('Por favor confirma tu contraseña.')
      .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden.'),
  });

  const initialValues = {
    password: '',
    confirmPassword: '',
    secretKey: token,
  };
  const formik = useFormik({
    initialValues,
    validationSchema: confirmPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      setTimeout(() => {
        createPass(values)
          .then((res) => {
            // const { accessToken } = res.data;
            setLoading(false);
            // dispatch(auth.actions.login(accessToken));
            toast(`Se actualizaron los datos correctamente!`, {
              position: 'top-center',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'light',
            });
            history.push('/auth/login');
          })
          .catch((err) => {
            let resMessageToast: string = '';
            if (err.response && err.response.data) {
              const {
                data: { message, details = null },
              } = err.response;
              resMessageToast = `${message}`;
              if (details.length) {
                setStatus(`${details[0]?.message}`);
              }
              setLoading(false);
              setSubmitting(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            } else {
              SimpleAlert('Error al iniciar sesión', 3000, 'error');
              setLoading(false);
              setSubmitting(false);
            }
          });
      }, 1000);
    },
  });

  return (
    <>
      <div>
        <img
          alt="Logo softnet"
          src={toAbsoluteUrl('/media/logos/logo-softnet.svg')}
          className="w-150px m-9"
        />
      </div>

      <div className="d-flex justify-content-center align-items-center h-75">
        <div className="mx-5 pt-0">
          <form
            className="form w-30 p-5 rounded"
            onSubmit={formik.handleSubmit}
            noValidate
            id="kt_login_signin_form"
          >
            {/* begin::Heading */}

            <div className="d-flex flex-column justify-content-center align-items-center text-center mb-5">
              <div
                className="w-100"
                style={{ maxWidth: '500px', marginBottom: '50px' }}
              >
                <h1 className="text-dark mb-3 fs-3">Crear contraseña</h1>
                <p
                  className="text-dark text-gray-600 fs-6"
                  style={{ textAlign: 'justify' }}
                >
                  Tu contraseña debe tener al menos 8 caracteres, incluyendo
                  mayúsculas, minúsculas, números y símbolos.
                </p>
              </div>
            </div>

            {/* begin::Heading */}

            {formik.status && (
              <div className="mb-lg-15 alert alert-danger">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            {/* begin::Form group */}
            {/* end::Form group */}

            {/* begin::Form group */}
            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex flex-stack mb-2">
                  {/* begin::Label */}
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Ingresa la contraseña
                  </label>
                </div>
              </div>

              <div className={clsx('position-relative')}>
                <input
                  type={visivilityFirst ? 'text' : 'password'}
                  placeholder="Contraseña"
                  autoComplete="off"
                  {...formik.getFieldProps('password')}
                  className={clsx('form-control form-control-lg')}
                />
                <span
                  className={clsx(
                    'btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                  )}
                  data-kt-password-meter-control={'visibility'}
                  onClick={validateVisibilityFirst}
                >
                  {visivilityFirst ? (
                    <i className={clsx('bi bi-eye fs-2')}></i>
                  ) : (
                    <i className={clsx('bi bi-eye-slash fs-2')}></i>
                  )}
                </span>
              </div>

              {formik.touched.password && formik.errors.password && (
                <div className="fv-plugins-message-container text-danger mt-3">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.password}</span>
                  </div>
                </div>
              )}
            </div>
            <div className="fv-row mb-10">
              <div className="d-flex justify-content-between mt-n5">
                <div className="d-flex flex-stack mb-2">
                  {/* begin::Label */}
                  <label className="form-label fw-bolder text-dark fs-6 mb-0">
                    Confirmar contraseña
                  </label>
                </div>
              </div>

              <div className={clsx('position-relative')}>
                <input
                  type={visivility ? 'text' : 'password'}
                  placeholder="Contraseña"
                  autoComplete="off"
                  {...formik.getFieldProps('confirmPassword')}
                  className={clsx(
                    'form-control form-control-lg'
                    // {
                    //   'is-invalid': formik.touched.password && formik.errors.password,
                    // },
                    // {
                    //   'is-valid': formik.touched.password && !formik.errors.password,
                    // }
                  )}
                />
                <span
                  className={clsx(
                    'btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2'
                  )}
                  data-kt-password-meter-control={'visibility'}
                  onClick={validateVisibility}
                >
                  {visivility ? (
                    <i className={clsx('bi bi-eye fs-2')}></i>
                  ) : (
                    <i className={clsx('bi bi-eye-slash fs-2')}></i>
                  )}
                </span>
              </div>

              {formik.touched.confirmPassword && formik.errors.confirmPassword && (
                <div className="fv-plugins-message-container text-danger mt-3">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.confirmPassword}</span>
                  </div>
                </div>
              )}
            </div>
            {/* end::Form group */}

            {/* begin::Action */}
            <div className="text-center">
              <button
                type="submit"
                id="kt_sign_in_submit"
                className="btn btn-lg btn-primary w-100 mb-5"
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!loading && <span className="indicator-label">Continuar</span>}
                {loading && (
                  <span
                    className="indicator-progress"
                    style={{ display: 'block' }}
                  >
                    Espere por favor...
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            </div>
            {/* end::Action */}
          </form>
        </div>
      </div>
    </>
  );
}
