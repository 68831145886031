import React from 'react';
import { formatDateMonth, formatTime, formatFullTime } from '../Functions';

type Props = {
  infoTimeLine: any;
};

const LineTime: React.FC<Props> = ({ infoTimeLine }) => {
  const getTextColor = (value: number) => {
    if (value === 4) return 'text-danger';
    if (value === 3 || value === 6 || value === 7 || value === 8)
      return 'text-success';
    if (value === 1 || value === 5) return 'text-warning';
    if (value === 2) return 'text-primary';
    return 'text-muted';
  };

  return (
    <div className="card">
      <div className="card-header d-flex flex-column align-items-start border-0 mb-5">
        <h3 className="card-title">
          <span className="fw-bold text-gray-900">Identificación</span>
        </h3>
        <div className="row w-100">
          <div className="col text-start">
            <span className="text-muted fw-semibold fs-7">ID envio</span>
          </div>
          <div className="col text-end">
            <span className="fw-semibold fs-7">{infoTimeLine.id}</span>
          </div>
        </div>
      </div>

      <div
        className="card-body pt-5 scroll-y overflow-auto"
        style={{ maxHeight: '300px' }}
      >
        <div className="timeline-label">
          {infoTimeLine && infoTimeLine.comments.length > 0
            ? infoTimeLine.comments
                .slice()
                .reverse()
                .map((item: any, index: any) => (
                  <div key={index} className="timeline-item">
                    <div className="timeline-label fw-bold text-gray-800 fs-6">
                      {formatFullTime(item.created_at)}
                    </div>
                    <div className="timeline-badge">
                      <i
                        className={`fa fa-genderless fs-1 ${getTextColor(
                          item.to.id
                        )}`}
                      ></i>
                    </div>
                    <div className="fw-mormal timeline-content  ps-3">
                      <div className="row">
                        <div className="col text-star fs-5 fw-bold">
                          {item.to.name}
                        </div>
                        <div className="col text-end fs-7">
                          {formatDateMonth(item.created_at)}
                        </div>
                      </div>
                      <div className="text-muted fw-bold fs-8">
                        {`${item.creator.first_name} ${item.creator.father_last_name}`}
                      </div>

                      <div className="text-muted">{item.comment}</div>
                    </div>
                  </div>
                ))
            : null}
          <div className="timeline-item">
            <div className="timeline-label fw-bold text-gray-800 fs-6">
              {formatFullTime(infoTimeLine.created_at)}
            </div>
            <div className="timeline-badge">
              <i className="fa fa-genderless fs-1 text-warning"></i>
            </div>

            <div className="fw-mormal timeline-content  ps-3">
              <div className="row">
                <div className="col text-star fs-5 fw-bold">Pendiente</div>
                <div className="col text-end fs-7">
                  {formatDateMonth(infoTimeLine.created_at)}
                </div>
              </div>
              <div className="text-muted"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { LineTime };
