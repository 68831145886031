import React, { useState } from 'react';
import { Modal } from 'react-bootstrap-v5';
import { KTSVG, SimpleAlert } from '../../../../_mexablock/helpers';
import { removeEmpresaSocio } from '../../../service/empresas/Empresas';

type Props = {
  show: boolean;
  id: number;

  handleClose: (updateGrid: boolean) => void;
};

const ConfirmModal: React.FC<Props> = ({ show, id, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [statusError, setStatusError] = useState('');

  const handlerConfirmStatus = () => {
    setLoading(true);
    setTimeout(() => {
      removeEmpresaSocio(id)
        .then((res) => {
          const {
            data: { message },
          } = res;
          SimpleAlert(message, 3000, 'success');
          setLoading(false);
          handleClose(true);
        })
        .catch((err) => {
          let resMessageToast: string = '';
          const {
            data: { message, details = null },
          } = err.response;
          resMessageToast = `${message}`;

          setLoading(false);
          SimpleAlert(resMessageToast, 3000, 'error');
        });
    }, 1000);
  };
  return (
    <Modal
      id="kt_modal_active_user"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-500px"
      show={show}
      onHide={() => {
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            onClick={() => {
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>
        <div className="modal-body">
          <div className="modal-body d-flex flex-column align-items-center text-center">
            <img
              src="/media/icons/duotune/general/gen057.svg"
              alt="Icono"
              width="100"
              height="100"
              className="mb-9"
            />

            <div className="fs-1 fw-bolder">
              ¿Estás seguro de que quieres desactivar esta cuenta?
            </div>
          </div>

          <div className="text-center pt-8">
            <button
              type="reset"
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              onClick={() => {
                handleClose(false);
              }}
            >
              Cancelar
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              data-kt-users-modal-action="submit"
              disabled={loading}
              onClick={handlerConfirmStatus}
            >
              {!loading && <span className="indicator-label">Aceptar</span>}
              {loading && (
                <span
                  className="indicator-progress"
                  style={{ display: 'block' }}
                >
                  Espere por favor...
                  <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                </span>
              )}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { ConfirmModal };
