import React, { useState, useEffect } from 'react';
import {
  formatDateMonth,
  KTSVG,
  LoadingGrid,
  toAbsoluteUrl,
} from '../../../../_mexablock/helpers';
import { TableList } from '../../../../_mexablock/helpers/components/TableList';
import { CreateModal } from '../_modals/CreateModal';
import { UpdateModal } from '../_modals/UpdateModal';
import { useGetAll } from '../hooks/DistribuidorHook';
import { ConfirmModal } from '../_modals/ConfirmModal';
import { MenuComponent } from '../../../../_mexablock/assets/ts/components';

const View: React.FC = () => {
  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [dataUpdModal, setDataUpdModal] = useState();
  const [dataActModal, setDataActModal] = useState<number>(0);
  const [visivilityModalUpd, setVisivilityModalUpd] = useState<boolean>(false);
  const [visivilityModalAct, setVisivilityModalAct] = useState<boolean>(false);
  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [initFilterValues] = useState({
    nombre: '',
  });
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    setVisivilityModal(!visivilityModal);
  };

  const updRegister = (data: any) => {
    setDataUpdModal(data);
    setVisivilityModalUpd(true);
  };

  const actRegister = (id: number) => {
    setDataActModal(id);
    setVisivilityModalAct(true);
  };
  const columns: any = [
    {
      id: 'profile_photo',
      name: '',
      cell: (row: any) => imageRender(row.user),
    },

    {
      id: 'created_at',
      name: <div className="fw-bolder text-muted me-5">Fecha</div>,
      selector: (row: any) => formatDateMonth(row.created_at),
      sortable: true,
      width: '110px',
    },
    {
      id: 'contribuyente',
      name: (
        <div className="fw-bolder text-muted me-5">Tipo de contribuyente</div>
      ),
      selector: (row: any) => row.id_type_person,
      cell: (row: any) => (
        <>
          {row.id_type_person === 1 ? <span>Fisica</span> : <span>Moral</span>}
        </>
      ),
      sortable: true,
      width: '200px',
    },
    {
      id: 'business_name',
      name: <div className="fw-bolder text-muted me-5">Razón social</div>,
      selector: (row: any) => row.business_name,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const a = rowA.business_name.toLowerCase();
        const b = rowB.business_name.toLowerCase();

        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      },
      width: '200px',
    },
    {
      id: 'encargado',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) =>
        `${row.user.first_name} ${row.user.father_last_name} ${row.user.mother_last_name}`,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const fullNameA = `${rowA.user.first_name} ${rowA.user.father_last_name} ${rowA.user.mother_last_name}`;
        const fullNameB = `${rowB.user.first_name} ${rowB.user.father_last_name} ${rowB.user.mother_last_name}`;

        return fullNameA.localeCompare(fullNameB, 'es', {
          sensitivity: 'base',
        });
      },
      width: '200px',
    },
    {
      id: 'correo',
      name: <div className="fw-bolder text-muted me-5">Correo</div>,
      selector: (row: any) => row.user.email,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const emailA = rowA.user.email.toLowerCase();
        const emailB = rowB.user.email.toLowerCase();

        return emailA.localeCompare(emailB, 'es', {
          sensitivity: 'base',
        });
      },
      width: '200px',
    },
    {
      id: 'active',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      cell: (row: any) => (
        <>
          {row.active ? (
            <span className="badge py-3 px-4 fs-7 badge-light-success">
              Activo
            </span>
          ) : (
            <span className="badge py-3 px-4 fs-7 badge-light-warning">
              Inactivo
            </span>
          )}
        </>
      ),
      sortable: false,
    },

    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
    },
  ];

  const imageRender = (user: any) => {
    return (
      <>
        <div className="symbol symbol-40px me-5">
          <img
            src={
              user.profile_photo
                ? `${process.env.REACT_APP_PUBLIC}users/${user.profile_photo}`
                : toAbsoluteUrl('/media/avatars/blank.png')
            }
            crossOrigin="anonymous"
            className=""
            alt=""
          />
        </div>
      </>
    );
  };

  const dropdownRender = (data: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                href="#/"
                onClick={() => {
                  updRegister(data);
                }}
              >
                Editar
              </a>
            </div>
            {data.active ? (
              <div className="menu-item px-3">
                <a
                  className="menu-link px-3"
                  data-kt-users-table-filter="delete_row"
                  href="#/"
                  onClick={() => {
                    actRegister(data.id);
                  }}
                >
                  Deshabilitar
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              {/* <Filter
                initFilterValues={initFilterValues}
                onCleanFilter={onCleanFilter}
                onSearchFilter={onSearchFilter}
              /> */}
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                <a
                  href="#/"
                  className="btn btn-sm btn-light-primary"
                  id="kt_toolbar_primary_button"
                  onClick={addRegister}
                >
                  <KTSVG
                    path="/media/icons/duotune/arrows/arr075.svg"
                    className="svg-icon-5 svg-icon-gray-500 me-1"
                  />
                  Nuevo
                </a>
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <CreateModal
        id_row={0}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateModal>
      <UpdateModal
        infoDis={dataUpdModal}
        show={visivilityModalUpd}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModalUpd(!visivilityModalUpd);
        }}
      />
      <ConfirmModal
        id={dataActModal}
        show={visivilityModalAct}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModalAct(!visivilityModalAct);
        }}
      />
    </>
  );
};

export { View };
