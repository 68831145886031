import React, { FC } from 'react';
import { PageTitle, PageLink } from '../../../../_mexablock/layout/core';
import { View } from './components/View';

const ConfigEquiposPage: FC = () => {
  return (
    <>
      <PageTitle>Configuración de equipos</PageTitle>
      <View />
    </>
  );
};

export { ConfigEquiposPage };
