import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAllConsignment(year: string) {
  const response = await axios.get(
    `${API_URL}operation/consignment/year-totals?year=${year}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function getAllIndicatorsConsignment() {
  const response = await axios.get(`${API_URL}operation/consignment/current`);
  const data = response.data.doc.data;
  return data;
}
