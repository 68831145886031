import axios from 'axios';
const API_URL = process.env.REACT_APP_API;

export async function getAllBalance(year: string) {
  const response = await axios.get(
    `${API_URL}operation/compra-venta/year-totals?year=${year}`
  );
  const data = response.data.doc.data;
  return data;
}
export async function getAllIndicatorsBalance() {
  const response = await axios.get(`${API_URL}operation/compra-venta/current`);
  const data = response.data.doc.data;
  return data;
}
