import React, { useEffect, useState } from 'react';
import {
  KTIcon,
  SimpleAlert,
  toAbsoluteUrl,
} from '../../../../_mexablock/helpers';
import { Modal } from 'react-bootstrap-v5';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import {
  Elements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { getClientSecret } from '../../../service/paymentMethod/PaymentMethod';

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY || '';
const stripePromise = loadStripe(STRIPE_KEY);

const cardSchema = Yup.object().shape({
  nameOnCard: Yup.string()
    .max(20, 'El nombre no debe tener más de 20 caracteres')
    .required('Nombre es requerido.'),
});

const initValues = {
  nameOnCard: '',
};

type FormPaymentMethodProps = {
  clientSecretBack: string | undefined;
  handleClose: (updateGrid: boolean) => void;
};

const FormPaymentMethod: React.FC<FormPaymentMethodProps> = ({
  clientSecretBack,
  handleClose,
}) => {
  const [loading, setLoading] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  const formik = useFormik({
    initialValues: initValues,
    validationSchema: cardSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true);

      if (!stripe || !elements) {
        setStatus('Stripe no está listo');
        setLoading(false);
        setSubmitting(false);
        return;
      }

      // const cardElement = elements.getElement(CardElement);
      const cardNumberElement = elements.getElement(CardNumberElement);
      const cardExpiryElement = elements.getElement(CardExpiryElement);
      const cardCvcElement = elements.getElement(CardCvcElement);

      const clientSecret = clientSecretBack ?? 'defaultClientSecret';

      const { error, setupIntent } = await stripe.confirmCardSetup(
        clientSecret,
        {
          payment_method: {
            card: cardNumberElement!,
            billing_details: {
              name: values.nameOnCard,
            },
          },
        }
      );

      if (error) {
        console.error(
          'Error en la configuración de la tarjeta:',
          error.message
        );
        setStatus(`Hubo un problema: ${error.message}`);
        setLoading(false);
        setSubmitting(false);
      } else if (setupIntent && setupIntent.status === 'succeeded') {
        SimpleAlert('Tarjeta guardada exitosamente', 3000, 'success');
        setLoading(false);
        setSubmitting(false);
        handleClose(true);
      } else if (setupIntent) {
      } else {
      }
    },
  });

  return (
    <form
      id="kt_modal_add_user_form"
      className="form"
      action="#"
      onSubmit={formik.handleSubmit}
    >
      <div
        className="d-flex flex-column scroll-y pe-7"
        id="kt_modal_add_user_scroll"
        data-kt-scroll="true"
        data-kt-scroll-activate="{ default: false, lg: true }"
        data-kt-scroll-max-height="auto"
        data-kt-scroll-dependencies="#kt_modal_add_user_header"
        data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
        data-kt-scroll-offset="300px"
      >
        {formik.status && (
          <div className="alert alert-danger">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        <div className="row">
          <div className="col-6 mx-auto mt-10">
            <Cards
              number="****************"
              expiry="**/**"
              cvc="***"
              name="Softcell"
            />
          </div>
          <div className="col-6">
            <div className="d-flex flex-column mb-7 fv-row">
              <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                <span className="required">Titular de la tarjeta</span>
              </label>

              <input
                type="text"
                className="form-control form-control-solid"
                placeholder="Ej. Rodolfo Rivera"
                name="nameOnCard"
                value={formik.values.nameOnCard}
                onChange={(e) => {
                  const regex = /^[a-zA-Z\s]*$/;
                  if (regex.test(e.target.value)) {
                    formik.handleChange(e);
                  }
                }}
                onBlur={formik.handleBlur}
              />
              {formik.touched.nameOnCard && formik.errors.nameOnCard ? (
                <div className="text-danger mt-1">
                  {formik.errors.nameOnCard}
                </div>
              ) : null}
            </div>

            <div className="d-flex flex-column mb-7 fv-row">
              <label className="required fs-6 fw-bold form-label mb-2">
                Número de la tarjeta
              </label>

              <div className="position-relative">
                <CardNumberElement className="form-control form-control-solid" />

                <div className="position-absolute translate-middle-y top-50 end-0 me-5">
                  <img
                    src={toAbsoluteUrl('media/svg/card-logos/visa.svg')}
                    alt=""
                    className="h-25px"
                  />
                  <img
                    src={toAbsoluteUrl('media/svg/card-logos/mastercard.svg')}
                    alt=""
                    className="h-25px"
                  />
                  <img
                    src={toAbsoluteUrl(
                      'media/svg/card-logos/american-express.svg'
                    )}
                    alt=""
                    className="h-25px"
                  />
                </div>
              </div>
            </div>

            <div className="row mb-10">
              <div className="col-md-8 fv-row">
                <label className="required fs-6 fw-bold form-label mb-2">
                  Fecha de vencimiento
                </label>

                <div className="row fv-row">
                  <div className="col-6">
                    <CardExpiryElement className="form-control form-control-solid" />
                  </div>
                </div>
              </div>

              <div className="col-md-4 fv-row">
                <label className="d-flex align-items-center fs-6 fw-bold form-label mb-2">
                  <span className="required">CVV</span>
                  {/* <i
                    className="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    title="Enter a card CVV code"
                  ></i> */}
                </label>

                <div className="position-relative">
                  <CardCvcElement className="form-control form-control-solid" />

                  <div className="position-absolute translate-middle-y top-50 end-0 me-3">
                    <KTIcon iconName="credit-cart" className="fs-2hx" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center pt-15">
        <button
          type="reset"
          className="btn btn-light me-3"
          data-kt-users-modal-action="cancel"
          onClick={() => {
            formik.resetForm();
            handleClose(false);
          }}
        >
          Cancelar
        </button>
        <button
          type="submit"
          className="btn btn-primary"
          data-kt-users-modal-action="submit"
          disabled={formik.isSubmitting || loading}
        >
          {!loading && <span className="indicator-label">Guardar</span>}
          {loading && (
            <span className="indicator-progress" style={{ display: 'block' }}>
              Espere por favor...
              <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          )}
        </button>
      </div>
    </form>
  );
};

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
  title: string;
};

const CreatePaymentMetodModal: React.FC<Props> = ({
  show,
  handleClose,
  title,
}) => {
  const [clientSecretBack, setClientSecretBack] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const secret = await getClientSecret();
        setClientSecretBack(secret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
      }
    };

    if (show) {
      fetchClientSecret();
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onHide={() => handleClose(false)}
      dialogClassName="modal-dialog modal-dialog-centered mw-850px"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h2>{title}</h2>
          <button
            onClick={() => handleClose(false)}
            className="btn btn-close"
          ></button>
        </div>

        <div className="modal-body scroll-y mx-5">
          <Elements
            stripe={stripePromise}
            options={{ clientSecret: clientSecretBack }}
          >
            <FormPaymentMethod
              clientSecretBack={clientSecretBack}
              handleClose={handleClose}
            />
          </Elements>
        </div>
      </div>
    </Modal>
  );
};

export { CreatePaymentMetodModal };
