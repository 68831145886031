import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'react-bootstrap-v5';
import {
  KTSVG,
  toAbsoluteUrl,
  SimpleAlert,
  maskPhoneNumber,
  formatPrice,
} from '../../../../../_mexablock/helpers';
import { NumericFormat } from 'react-number-format';
import clsx from 'clsx';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { StepperComponent } from '../../../../../_mexablock/assets/ts/components';
import {
  validarIMEI,
  validateICCID,
  validateEmail,
} from '../../../../service/operacion/Validacion';
import * as auth from '../../../../modules/auth/redux/AuthRedux';
import { useSelectOffers } from '../hooks/ActivacionHook';
import { createActivation } from '../../../../service/operacion/Activaciones';
import { useSelectGeneric } from '../../../catalogos/generico/hooks/genericoHook';
import { getUserByToken } from '../../../../modules/auth/redux/AuthCRUD';

type Props = {
  show: boolean;
  handleClose: (updateData: boolean) => void;
};
const schemaNewActivation = [
  Yup.object({
    imei: Yup.string()
      .min(14, 'El IMEI debe tener mínimo 14 dígitos.')
      .max(16, 'El IMEI debe tener máximo 16 dígitos.')
      .required('Requerido.'),
  }),
  Yup.object({
    first_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    mother_last_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    father_last_name: Yup.string()
      .max(20, 'Máximo 20 caracteres')
      .required('Requerido.'),
    email: Yup.string()
      .email('Formato de correo inválido.')
      .min(3, 'Mínimo 3 caracteres.')
      .max(50, 'Máximo 50 caracteres.')
      .required('Requerido.'),
    birthdate: Yup.date().required('Requerido.'),
    gender_id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  }),
  Yup.object({
    iccid: Yup.string()
      .required('Requerido.')
      .min(19, 'Formato de ICCID inválido')
      .max(19, 'Formato de ICCID inválido'),
  }),
  Yup.object({
    offer_id: Yup.number().min(1, 'Requerido.').required('Requerido.'),
  }),
];

let maxDate = new Date();
maxDate.setDate(maxDate.getDate() + 1);

const currentDate = new Date();
const fechaMaxima = new Date(currentDate);
fechaMaxima.setFullYear(currentDate.getFullYear() - 18);
const fechaMaximaString = fechaMaxima.toISOString().split('T')[0];

const initValues = {
  imei: '',
  iccid: '',
  schedule_date: maxDate,
  offer_id: 0,
  inmediality: 1,
  first_name: '',
  mother_last_name: '',
  father_last_name: '',
  email: '',
  birthdate: fechaMaxima,
  gender_id: 0,
};

const NewActivationModal: React.FC<Props> = ({ show, handleClose }) => {
  const dispatch = useDispatch();
  const { data: offers } = useSelectOffers(show, 'activation');
  const { catalog: genders } = useSelectGeneric('GEN');
  const [loading, setLoading] = useState(false);
  const [PhoneNumber, setPhoneNumber] = useState<string>('');
  const [textStepper, setTextStepper] = useState('Continuar');
  const [currentSchema, setCurrentSchema] = useState(schemaNewActivation[0]);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };

  useEffect(() => {
    if (show) {
      loadStepper();
    }
  }, [stepperRef, show]);

  const formNewActivation = useFormik({
    initialValues: initValues,
    validationSchema: currentSchema,
    enableReinitialize: true,
    onSubmit: (values, { setStatus, setSubmitting, setFieldError }) => {
      if (!stepper.current) {
        return;
      }
      switch (stepper.current.currentStepIndex) {
        case 1:
          setLoading(true);
          setTimeout(() => {
            validarIMEI(values)
              .then((res) => {
                if (!stepper.current) {
                  return;
                }
                const {
                  data: { doc },
                } = res;
                const { homologated } = doc.data.imei;
                if (
                  homologated === 'COMPATIBLE' ||
                  homologated === 'COMPATIBLE HOMOLOGADO' ||
                  homologated === 'COMPATIBLE PROBADO'
                ) {
                  stepper.current.goNext();
                  setCurrentSchema(schemaNewActivation[1]);
                } else {
                  SimpleAlert(
                    'Su dispositivo no es compatible con el servicio.',
                    3000,
                    'error'
                  );
                }
                setLoading(false);
              })
              .catch((err) => {
                setLoading(false);
                SimpleAlert(
                  'Error al validar el IMEI, revisa que el IMEI esté correcto.',
                  3000,
                  'error'
                );
              });
          }, 1000);

          break;
        case 2:
          setLoading(true);
          setTimeout(() => {
            validateEmail(values)
              .then(async (res: any) => {
                if (!stepper.current) {
                  return;
                }
                const { disponible, mensaje } = res.data.doc.data;
                if (disponible) {
                  stepper.current.goNext();
                  setCurrentSchema(schemaNewActivation[2]);
                  setLoading(false);
                } else {
                  SimpleAlert(mensaje, 3000, 'error');
                  setLoading(false);
                }
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message },
                } = err.response;
                resMessageToast = `${message}`;
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 1000);
          break;
        case 3:
          setLoading(true);
          setTimeout(() => {
            validateICCID(values)
              .then(async (res) => {
                const { success, error, activation } = res.data.doc.data;
                if (!activation) {
                  if (!stepper.current) {
                    return;
                  }
                  if (success) {
                    stepper.current.goNext();
                    setCurrentSchema(schemaNewActivation[3]);
                    setLoading(false);
                    setTextStepper('Activar');
                  } else {
                    SimpleAlert(error, 3000, 'error');
                    setLoading(false);
                  }
                } else {
                  SimpleAlert('ICCID YA ACTIVO.', 3000, 'error');
                  setLoading(false);
                }
              })
              .catch((err) => {
                SimpleAlert(
                  'Error al validar el ICCID, revisa que el ICCID esté correcto.',
                  3000,
                  'error'
                );
                setLoading(false);
              });
          }, 1000);
          break;
        case 4:
          setLoading(true);
          setTimeout(() => {
            createActivation(values)
              .then(async (res) => {
                if (!stepper.current) {
                  return;
                }
                const { msisdn } = res.data.doc.data;
                stepper.current.goNext();
                setTextStepper('Finalizar');
                setPhoneNumber(msisdn);
                SimpleAlert('La linea fue activada con éxito', 3000, 'success');
                setLoading(false);

                // UPDATE SALDO
                const user = await getUserByToken();
                dispatch(auth.actions.fulfillUser(user));
              })
              .catch((err) => {
                let resMessageToast: string = '';
                const {
                  data: { message },
                } = err.response;
                resMessageToast = `${message}`;
                SimpleAlert(resMessageToast, 3000, 'error');
                setLoading(false);
              });
          }, 1000);
          break;
        case 5:
          if (!stepper.current) {
            return;
          }
          handleClose(true);
          stepper.current.goto(1);
          setTextStepper('Continuar');
          setPhoneNumber('');
          setCurrentSchema(schemaNewActivation[0]);
          formNewActivation.resetForm();
          setLoading(false);
          break;
      }
    },
  });
  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current?.goPrev();
    setCurrentSchema(schemaNewActivation[stepper.current.currentStepIndex - 1]);
    switch (stepper.current.currentStepIndex - 1) {
      case 1:
        setTextStepper('Continuar');
        break;
      case 2:
        setTextStepper('Continuar');
        break;
      case 3:
        setTextStepper('Continuar');
        break;
      case 4:
        setTextStepper('Continuar');
        break;
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const radioValue = Number(e.target.value);
    formNewActivation.setFieldValue('inmediality', radioValue);
  };

  const handleChangeInput = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: string
  ) => {
    const value = event.target.value;
    const valueReg = value.replace(/\d/g, '');
    const cleanInput = valueReg.replace(/^\s+|\s+(?=\s)/g, '');
    const inputLimpio = cleanInput.replace(/[^\wáéíóúüÁÉÍÓÚÜñÑ\s]/g, '');
    formNewActivation.setFieldValue(key, inputLimpio);
  };

  return (
    <Modal
      id="kt_modal_new_activation"
      tabIndex={-1}
      aria-hidden="true"
      dialogClassName="modal-dialog modal-dialog-centered mw-900px"
      show={show}
      onHide={() => {
        stepper?.current?.goto(1);
        formNewActivation.resetForm();
        setCurrentSchema(schemaNewActivation[0]);
        setTextStepper('Continuar');
        setPhoneNumber('');
        handleClose(false);
      }}
    >
      <div className="modal-content">
        <div className="modal-header pb-0 border-0 justify-content-end">
          <div
            className="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
            onClick={() => {
              stepper?.current?.goto(1);
              formNewActivation.resetForm();
              setCurrentSchema(schemaNewActivation[0]);
              setTextStepper('Continuar');
              setPhoneNumber('');
              handleClose(false);
            }}
          >
            <KTSVG
              className="svg-icon-2"
              path="/media/icons/duotune/arrows/arr061.svg"
            />
          </div>
        </div>

        <div className="modal-body pt-0">
          <div className="text-center mb-13">
            <h1 className="mb-3">Nueva Activación</h1>
          </div>
          <div
            ref={stepperRef}
            className="stepper stepper-links d-flex flex-column gap-5 first"
            id="kt_modal_create_app_stepper"
          >
            <div className="stepper-nav justify-content-center py-2">
              <div
                className="stepper-item current"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Validación IMEI</h3>
              </div>

              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Datos del Cliente</h3>
              </div>

              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Datos de la Activación</h3>
              </div>

              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Ofertas</h3>
              </div>
              <div
                className="stepper-item pending"
                data-kt-stepper-element="nav"
              >
                <h3 className="stepper-title">Completada</h3>
              </div>
            </div>
            <form
              className="mx-auto w-100 mw-800px pt-10 pb-10"
              id="kt_modal_top_up_wallet_stepper_form"
              autoComplete="off"
              onSubmit={formNewActivation.handleSubmit}
            >
              <div
                className="current min-h-550px"
                data-kt-stepper-element="content"
              >
                <div className="row w-100 h-auto">
                  <div className="col-md-6 fv-row  mb-10">
                    <div className="w-100 d-flex">
                      <label className=" fw-bold fs-6 mb-2 w-auto required">
                        IMEI a validar
                      </label>
                      {formNewActivation.touched.imei &&
                        formNewActivation.errors.imei && (
                          <div className="fv-plugins-message-container text-danger ms-5">
                            <span role="alert">
                              {formNewActivation.errors.imei}
                            </span>
                          </div>
                        )}
                    </div>
                    <NumericFormat
                      name="imei"
                      className="form-control text-gray-700 placeholder-gray-400 mw-500px"
                      value={formNewActivation.values.imei}
                      decimalScale={0}
                      decimalSeparator="."
                      minLength={14}
                      maxLength={16}
                      fixedDecimalScale
                      placeholder="IMEI"
                      prefix=""
                      thousandSeparator=""
                      onValueChange={(values) => {
                        const { value } = values;
                        formNewActivation.setFieldValue(
                          'imei',
                          value ? value : ''
                        );
                      }}
                    />
                  </div>
                  <div className="col-md-6 fv-row fv-plugins-icon-container">
                    <div className=" w-100 text-center">
                      <span className="badge badge-light-primary fs-5">
                        Como obtener el IMEI
                      </span>
                    </div>
                    <div className="w-100 text-center">
                      <img
                        alt="GIF"
                        src={toAbsoluteUrl('/media/gif/validacion.gif')}
                        className="h-400px"
                        style={{ borderRadius: '10px' }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Nombre
                          </label>
                          {formNewActivation.touched.first_name &&
                            formNewActivation.errors.first_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewActivation.errors.first_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Nombre"
                          {...formNewActivation.getFieldProps('first_name')}
                          className="form-control mb-3"
                          type="text"
                          name="first_name"
                          autoComplete="off"
                          onChange={(e: any) => {
                            handleChangeInput(e, 'first_name');
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Apellido paterno
                          </label>
                          {formNewActivation.touched.father_last_name &&
                            formNewActivation.errors.father_last_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewActivation.errors.father_last_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Apellido paterno"
                          {...formNewActivation.getFieldProps(
                            'father_last_name'
                          )}
                          className="form-control mb-3"
                          type="text"
                          name="father_last_name"
                          autoComplete="off"
                          onChange={(e: any) => {
                            handleChangeInput(e, 'father_last_name');
                          }}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Apellido materno
                          </label>
                          {formNewActivation.touched.mother_last_name &&
                            formNewActivation.errors.mother_last_name && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewActivation.errors.mother_last_name}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Apellido materno"
                          {...formNewActivation.getFieldProps(
                            'mother_last_name'
                          )}
                          className="form-control mb-3"
                          type="text"
                          name="mother_last_name"
                          autoComplete="off"
                          onChange={(e: any) => {
                            handleChangeInput(e, 'mother_last_name');
                          }}
                        />
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Fecha de nacimiento
                          </label>
                          {formNewActivation.touched.birthdate &&
                            formNewActivation.errors.birthdate && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewActivation.errors.birthdate}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          type="date"
                          className="form-control"
                          value={
                            new Date(formNewActivation.values.birthdate)
                              .toISOString()
                              .split('T')[0]
                          }
                          max={fechaMaximaString}
                          onChange={(e) => {
                            if (e.target.value) {
                              const fecha = new Date(e.target.value)
                                .toISOString()
                                .split('T')[0];
                              formNewActivation.setFieldValue(
                                'birthdate',
                                fecha
                              );
                            }
                          }}
                        ></input>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Genero
                          </label>
                          {formNewActivation.touched.gender_id &&
                            formNewActivation.errors.gender_id && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewActivation.errors.gender_id}
                                </span>
                              </div>
                            )}
                        </div>
                        <select
                          className="form-select"
                          {...formNewActivation.getFieldProps('gender_id')}
                        >
                          <option value={0}>Seleccione</option>
                          {genders.map((item, index) => (
                            <option key={index} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6">
                        <div className="w-100 d-flex">
                          <label className="required fs-6 fw-semibold form-label mb-2">
                            Correo
                          </label>
                          {formNewActivation.touched.email &&
                            formNewActivation.errors.email && (
                              <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                <span role="alert">
                                  {formNewActivation.errors.email}
                                </span>
                              </div>
                            )}
                        </div>
                        <input
                          placeholder="Correo"
                          {...formNewActivation.getFieldProps('email')}
                          className="form-control mb-3 mb-lg-0"
                          type="text"
                          name="email"
                          autoComplete="off"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        ICCID
                      </label>
                      {formNewActivation.touched.iccid &&
                        formNewActivation.errors.iccid && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewActivation.errors.iccid}
                            </span>
                          </div>
                        )}
                    </div>

                    <NumericFormat
                      className="form-control mb-3"
                      {...formNewActivation.getFieldProps('iccid')}
                      decimalScale={0}
                      maxLength={19}
                      fixedDecimalScale
                      placeholder="Identificador (ICCID)"
                      prefix=""
                      onValueChange={(values: any) => {
                        const { floatValue } = values;
                        formNewActivation.setFieldValue('iccid', floatValue);
                      }}
                    />
                  </div>
                  <div className="fv-row mb-7">
                    <label className="required fs-6 fw-semibold form-label mb-2 mt-5">
                      Fecha de activación
                    </label>
                    {formNewActivation.touched.schedule_date &&
                      formNewActivation.errors.schedule_date && (
                        <div className="fv-plugins-message-container text-danger w-auto ms-5">
                          <span role="alert">
                            {formNewActivation.errors.schedule_date}
                          </span>
                        </div>
                      )}
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-check form-check-custom form-check-solid p-5">
                          <label className="form-check-label fw-bolder text-gray-800">
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              value={1}
                              onChange={handleOnChange}
                              checked={
                                formNewActivation.values.inmediality === 1
                              }
                              name="fecha_activacion"
                            />
                            Inmediatamente
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-check form-check-custom form-check-solid p-5">
                          <label className="form-check-label fw-bolder text-gray-800">
                            <input
                              className="form-check-input me-3"
                              type="radio"
                              value={2}
                              onChange={handleOnChange}
                              name="fecha_activacion"
                              checked={
                                formNewActivation.values.inmediality === 2
                              }
                            />
                            Programar
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  {formNewActivation.values.inmediality === 2 && (
                    <div className="fv-row mb-7">
                      <input
                        type="date"
                        className="form-control"
                        onKeyDown={(e) => e.preventDefault()}
                        value={
                          new Date(formNewActivation.values.schedule_date)
                            .toISOString()
                            .split('T')[0]
                        }
                        min={maxDate.toISOString().split('T')[0]}
                        onChange={(e) => {
                          if (e.target.value) {
                            const fecha = new Date(e.target.value)
                              .toISOString()
                              .split('T')[0];
                            formNewActivation.setFieldValue(
                              'schedule_date',
                              fecha
                            );
                          }
                        }}
                      ></input>
                    </div>
                  )}
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100 ms-20 me-20 h-auto">
                  <div className="fv-row mb-7 fv-plugins-icon-container">
                    <div className="w-100 d-flex">
                      <label className="required fs-6 fw-semibold form-label mb-2">
                        Seleciona una oferta
                      </label>
                      {formNewActivation.touched.offer_id &&
                        formNewActivation.errors.offer_id && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">
                              {formNewActivation.errors.offer_id}
                            </span>
                          </div>
                        )}
                    </div>
                    {offers.map((item: any, index: number) => (
                      <a
                        href="#/"
                        key={index}
                        className={clsx(
                          'btn btn-outline btn-flex flex-column flex-stack pb-5 ms-5 mt-5 border-hover-primary',
                          {
                            'btn-active-light-primary active':
                              formNewActivation.values.offer_id === item.id,
                          }
                        )}
                        style={{
                          width: '200px',
                          height: '150px',
                          border: 'solid 1px #dbdfe9',
                        }}
                        onClick={() => {
                          formNewActivation.setFieldValue(`offer_id`, item.id);
                        }}
                      >
                        <div className="">
                          <span
                            className={clsx(
                              ' fw-bold fs-2 d-block text-hover-primary ',
                              {
                                ' text-primary':
                                  formNewActivation.values.offer_id === item.id,
                                ' text-gray-800':
                                  formNewActivation.values.offer_id !== item.id,
                              }
                            )}
                          >
                            {`${item.gb} GB`}
                          </span>
                          <span
                            className={clsx(' fw-semibold fs-5', {
                              ' text-primary':
                                formNewActivation.values.offer_id === item.id,
                              ' text-gray-600':
                                formNewActivation.values.offer_id !== item.id,
                            })}
                          >
                            {`${formatPrice(item.price, 'es-MX', 'MXN', 2, 2)}`}
                          </span>
                          <span
                            className={clsx('  fw-semibold fs-7', {
                              ' text-primary':
                                formNewActivation.values.offer_id === item.id,
                              ' text-gray-500':
                                formNewActivation.values.offer_id !== item.id,
                            })}
                          >
                            {` / ${item.commercial_time}`}
                          </span>
                        </div>

                        <div
                          className={clsx(' fw-semibold fs-6 mt-1', {
                            ' text-primary':
                              formNewActivation.values.offer_id === item.id,
                            ' text-gray-600':
                              formNewActivation.values.offer_id !== item.id,
                          })}
                        >
                          {item.share_internet ? (
                            <KTSVG
                              className="svg-icon-3 svg-icon-success"
                              path="/media/icons/duotune/arrows/arr016.svg"
                            />
                          ) : (
                            <KTSVG
                              className="svg-icon-3"
                              path="/media/icons/duotune/arrows/arr015.svg"
                            />
                          )}
                          Comparte internet
                        </div>
                        <div
                          className={clsx(' fw-semibold fs-6  mt-1', {
                            ' text-primary':
                              formNewActivation.values.offer_id === item.id,
                            ' text-gray-600':
                              formNewActivation.values.offer_id !== item.id,
                          })}
                        >
                          <KTSVG
                            className="svg-icon-3"
                            path="/media/icons/duotune/communication/com013.svg"
                          />
                          {`${item.minutes} Minutos`}
                        </div>
                        <div
                          className={clsx(' fw-semibold fs-6  mt-1', {
                            ' text-primary':
                              formNewActivation.values.offer_id === item.id,
                            ' text-gray-600':
                              formNewActivation.values.offer_id !== item.id,
                          })}
                        >
                          <KTSVG
                            className="svg-icon-3"
                            path="/media/icons/duotune/communication/com003.svg"
                          />
                          {`${item.sms} SMS`}
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div
                data-kt-stepper-element="content"
                className="pending min-h-550px"
              >
                <div className="w-100">
                  <div className="text-center">
                    <h1 className="fw-bold text-primary">
                      Activación exitosa!
                    </h1>

                    <div className="fw-semibold text-muted fs-4">
                      Este es tu número de teléfono:
                    </div>
                    <h1 className="fw-bold text-dark mt-10">
                      {maskPhoneNumber(PhoneNumber)}
                    </h1>
                  </div>
                  <div className="text-center px-4">
                    <img
                      className="mx-auto h-300px h-lg-300px"
                      src={toAbsoluteUrl(
                        '/media/illustrations/sketchy-1/ok.png'
                      )}
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex flex-stack pt-10">
                <div className="me-2">
                  {PhoneNumber === '' && (
                    <button
                      onClick={prevStep}
                      type="button"
                      className="btn btn-lg btn-light-primary me-3"
                      data-kt-stepper-action="previous"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr063.svg"
                        className="svg-icon-4 me-1"
                      />
                      Regresar
                    </button>
                  )}
                </div>

                <div>
                  <button
                    type="submit"
                    className="btn btn-lg btn-primary me-3"
                    disabled={loading}
                  >
                    {!loading && (
                      <>
                        <span className="indicator-label">{textStepper}</span>
                        {textStepper === 'Continuar' && (
                          <KTSVG
                            path="/media/icons/duotune/arrows/arr064.svg"
                            className="svg-icon-4 me-1 ms-1"
                          />
                        )}
                      </>
                    )}
                    {loading && (
                      <span
                        className="indicator-progress"
                        style={{ display: 'block' }}
                      >
                        Espere por favor...
                        <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export { NewActivationModal };
