import React, { useState, useEffect } from 'react';
import {
  formatDateMonth,
  KTSVG,
  LoadingGrid,
} from '../../../../_mexablock/helpers';
import { TableList } from '../../../../_mexablock/helpers/components/TableList';
import { CreateModal } from '../_modals/CreateModal';
import { useGetAll } from '../hooks/EmpresasHook';
import { shallowEqual, useSelector } from 'react-redux';
import { UserModel } from '../../../../app/modules/auth/models/UserModel';
import { RootState } from '../../../../setup/index';
import { userHasRole, toAbsoluteUrl } from '../../../../_mexablock/helpers';
import { MenuComponent } from '../../../../_mexablock/assets/ts/components';
import { useHistory } from 'react-router-dom';

import axios from 'axios';
import { UpdateModal } from '../_modals/UpdateModal';
import { ConfirmModal } from '../_modals/ConfirmModal';
const API_URL = process.env.REACT_APP_API;

const View: React.FC = () => {
  const user: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = user;
  const history = useHistory();
  const [loadingView, setloadingView] = useState(true);
  const [visivilityModal, setVisivilityModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [dataUpdModal, setDataUpdModal] = useState();

  const [reloadGrid, setReloadGrid] = useState(Math.random() * 40);
  const [dataActModal, setDataActModal] = useState<number>(0);
  const [visivilityModalAct, setVisivilityModalAct] = useState<boolean>(false);
  const [visivilityModalUpd, setVisivilityModalUpd] = useState<boolean>(false);

  const [initFilterValues] = useState({
    nombre: '',
  });
  const { data, loadingRequest } = useGetAll(reloadGrid, initFilterValues);

  useEffect(() => {
    if (loadingRequest > 0) {
      setloadingView(false);
    }
  }, [loadingRequest]);

  const addRegister = () => {
    setTitleModal('Nuevo');
    setVisivilityModal(!visivilityModal);
  };

  const updRegister = (data: any) => {
    setDataUpdModal(data);
    setVisivilityModalUpd(true);
  };

  const onDeshabilitRegister = (id: number) => {
    setDataActModal(id);
    setVisivilityModalAct(true);
  };

  const columns: any = [
    {
      id: 'profile_photo',
      name: '',
      cell: (row: any) => imageRender(row.user),
    },
    {
      id: 'created_at',
      name: <div className="fw-bolder text-muted me-5">Fecha</div>,
      selector: (row: any) => formatDateMonth(row.created_at),
      sortable: true,
      width: '110px',
    },
    {
      id: 'tipo',
      name: <div className="fw-bolder text-muted me-5">Tipo</div>,
      selector: (row: any) => row.type.name,
      sortable: true,
    },
    {
      id: 'contribuyente',
      name: (
        <div className="fw-bolder text-muted me-5">Tipo de contribuyente</div>
      ),
      selector: (row: any) => row.id_type_person,
      cell: (row: any) => (
        <>
          {row.id_type_person === 1 ? <span>Fisica</span> : <span>Moral</span>}
        </>
      ),
      sortable: true,
      width: '200px',
    },
    {
      id: 'business_name',
      name: <div className="fw-bolder text-muted me-5">Razón social</div>,
      selector: (row: any) => row.business_name,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const a = rowA.business_name.toLowerCase();
        const b = rowB.business_name.toLowerCase();

        if (a < b) return -1;
        if (a > b) return 1;
        return 0;
      },
      width: '200px',
    },

    {
      id: 'encargado',
      name: <div className="fw-bolder text-muted me-5">Nombre</div>,
      selector: (row: any) => row.user.first_name,
      cell: (row: any) =>
        `${row.user.first_name} ${row.user.father_last_name} ${row.user.mother_last_name}`,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const fullNameA = `${rowA.user.first_name} ${rowA.user.father_last_name} ${rowA.user.mother_last_name}`;
        const fullNameB = `${rowB.user.first_name} ${rowB.user.father_last_name} ${rowB.user.mother_last_name}`;

        return fullNameA.localeCompare(fullNameB, 'es', {
          sensitivity: 'base',
        });
      },
      width: '200px',
    },
    {
      id: 'correo',
      name: <div className="fw-bolder text-muted me-5">Correo</div>,
      selector: (row: any) => row.user.email,
      sortable: true,
      sortFunction: (rowA: any, rowB: any) => {
        const emailA = rowA.user.email.toLowerCase();
        const emailB = rowB.user.email.toLowerCase();

        return emailA.localeCompare(emailB, 'es', {
          sensitivity: 'base',
        });
      },
      width: '200px',
    },
    {
      id: 'active',
      name: <div className="fw-bolder text-muted me-5">Estatus</div>,
      cell: (row: any) => (
        <>
          {row.active ? (
            <span className="badge py-3 px-4 fs-7 badge-light-success">
              Activo
            </span>
          ) : (
            <span className="badge py-3 px-4 fs-7 badge-light-warning">
              Inactivo
            </span>
          )}
        </>
      ),
      sortable: false,
    },
    {
      id: 'acciones',
      name: <div className="fw-bolder text-muted me-5"></div>,
      cell: (row: any) => dropdownRender(row),
      sortable: false,
    },
  ];

  const imageRender = (user: any) => {
    return (
      <>
        <div className="symbol symbol-40px me-5">
          <img
            src={
              user.profile_photo
                ? `${process.env.REACT_APP_PUBLIC}users/${user.profile_photo}`
                : toAbsoluteUrl('/media/avatars/blank.png')
            }
            crossOrigin="anonymous"
            className=""
            alt=""
          />
        </div>
      </>
    );
  };

  setTimeout(() => {
    MenuComponent.reinitialization();
  }, 1000);

  const dropdownRender = (item: any) => {
    return (
      <>
        <div className="d-flex justify-content-end flex-shrink-0">
          <a
            href="#/"
            data-kt-menu="true"
            className="btn btn-light btn-active-light-primary btn-sm"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
          >
            Acciones
            <span className="svg-icon svg-icon-5 m-0">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="mh-50px"
              >
                <path
                  d="M11.4343 12.7344L7.25 8.55005C6.83579 8.13583 6.16421 8.13584 5.75 8.55005C5.33579 8.96426 5.33579 9.63583 5.75 10.05L11.2929 15.5929C11.6834 15.9835 12.3166 15.9835 12.7071 15.5929L18.25 10.05C18.6642 9.63584 18.6642 8.96426 18.25 8.55005C17.8358 8.13584 17.1642 8.13584 16.75 8.55005L12.5657 12.7344C12.2533 13.0468 11.7467 13.0468 11.4343 12.7344Z"
                  fill="currentColor"
                ></path>
              </svg>
            </span>
          </a>

          <div
            className="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-125px py-4"
            data-kt-menu="true"
          >
            <div className="menu-item px-3">
              <a
                className="menu-link px-3"
                onClick={() => {
                  updRegister(item);
                }}
              >
                Editar
              </a>
            </div>
            {item.active ? (
              <div className="menu-item px-3">
                <a
                  className="menu-link px-3"
                  data-kt-users-table-filter="delete_row"
                  href="#/"
                  onClick={() => {
                    onDeshabilitRegister(item.id);
                  }}
                >
                  Deshabilitar
                </a>
              </div>
            ) : null}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="row gy-5 g-xl-8">
        <div className="col-xl-12">
          <div className={`card card-xxl-stretch mb-5 mb-xl-12`}>
            <div className="card-header border-0 pt-5">
              <div className="card-title align-items-start flex-column"></div>
              <div
                className="card-toolbar"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                data-bs-trigger="hover"
                title="Nuevo registro"
              >
                {userHasRole(['SYSTEM_ADMIN'], userRoles) && data.length === 0 && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light-primary"
                    id="kt_toolbar_primary_button"
                    onClick={addRegister}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5 svg-icon-gray-500 me-1"
                    />
                    Nuevo
                  </a>
                )}
                {userHasRole(['ADMIN_MEXABLOCK'], userRoles) && (
                  <a
                    href="#/"
                    className="btn btn-sm btn-light-primary"
                    id="kt_toolbar_primary_button"
                    onClick={addRegister}
                  >
                    <KTSVG
                      path="/media/icons/duotune/arrows/arr075.svg"
                      className="svg-icon-5 svg-icon-gray-500 me-1"
                    />
                    Nuevo
                  </a>
                )}
              </div>
            </div>
            {loadingView ? (
              <LoadingGrid text="Cargando..."></LoadingGrid>
            ) : (
              <TableList data={data} columns={columns}></TableList>
            )}
          </div>
        </div>
      </div>
      <CreateModal
        id_row={0}
        title={titleModal}
        show={visivilityModal}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModal(!visivilityModal);
        }}
      ></CreateModal>
      <UpdateModal
        DataRegister={dataUpdModal}
        show={visivilityModalUpd}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModalUpd(!visivilityModalUpd);
        }}
      />
      <ConfirmModal
        id={dataActModal}
        show={visivilityModalAct}
        handleClose={(updateGrid: boolean) => {
          if (updateGrid) {
            setReloadGrid(Math.random() * 40);
          }
          setVisivilityModalAct(!visivilityModalAct);
        }}
      />
    </>
  );
};

export { View };
