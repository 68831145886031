import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  KTSVG,
  SimpleAlert,
  userHasRole,
  toAbsoluteUrl,
} from '../../../../../_mexablock/helpers';
import { Modal } from 'react-bootstrap-v5';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { NumericFormat } from 'react-number-format';
import { shallowEqual, useSelector } from 'react-redux';
import {
  approvisionamiento,
  verifyBalance,
} from '../../../../service/operacion/Cuentas';
import Select from 'react-select';
import { getSelectEmpresas } from '../../../../service/empresas/Empresas';
import { ApprovisionValues } from '../models/CuentaModel';
import { useHistory } from 'react-router-dom';
import { UserModel } from '../../../../modules/auth/models/UserModel';
import { RootState } from '../../../../../setup';
import { getAllSelect } from '../../../../service/distribuidores/Distribuidores';
import { styleSelect } from '../../../../../_mexablock/helpers/StyleSelect';
import { StepperComponent } from '../../../../../_mexablock/assets/ts/components';
import { getSelectCatalog } from '../../../../service/catalog/Catalogs';
import { getAllCommissions } from '../../../../service/commission/commission';
import AnimationSucces from '../../../../../_mexablock/helpers/components/SuccesResponse';

type Props = {
  show: boolean;
  handleClose: (updateGrid: boolean) => void;
};

const AprovisionamientoModal: React.FC<Props> = ({
  show,
  handleClose,
}: any) => {
  const userSesion: UserModel = useSelector<RootState>(
    ({ auth }) => auth.user,
    shallowEqual
  ) as UserModel;
  const { roles: userRoles } = userSesion;
  const [loading, setLoading] = useState(false);
  const stepperRef = useRef<HTMLDivElement | null>(null);
  const stepper = useRef<StepperComponent | null>(null);
  const [radioSelection, setRadioSelection] = useState('');
  const [empresas, setEmpresas] = useState<any[]>([]);
  const [distribuidores, setDistribuidores] = useState<any[]>([]);
  const [tiposPago, setTiposPago] = useState<any[]>();
  const [commissions, setCommisssions] = useState<any[]>([]);
  const [commission, setCommission] = useState(0);
  const [typeCommission, setTypeCommission] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [isDistribuidor, setIsDistribuidor] = useState(false);
  const [textStepper, setTextStepper] = useState('Continuar');
  const [aprovComplete, setAprovComplete] = useState<number>(0);
  const [fileName, setFileName] = useState<string>('');
  const [isConsignment, setIsConsignment] = useState<boolean>(false);
  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(
      stepperRef.current as HTMLDivElement
    );
  };
  const history = useHistory();
  const empresasOptions = useMemo(
    () => empresas.filter((emp) => emp.type.code === radioSelection),
    [empresas, radioSelection]
  );
  const typeClient = [
    {
      label: 'Empresa',
      value: 'empresa',
    },
    {
      label: 'Socio',
      value: 'socio',
    },
  ];
  const typeAprov = [
    {
      label: 'Venta directa',
      value: 'direct',
    },
    {
      label: 'Consignación',
      value: 'consig',
    },
  ];

  const initialValues: ApprovisionValues = {
    empresa: null,
    efectivo: 0,
    virtual: 0,
    comments: '',
    file: null,
    tipoPago: null,
    cliente: '',
    typeAprov: null,
  };

  const validationSchemas = [
    Yup.object({
      virtual: Yup.number().min(1).required(),
      empresa: Yup.mixed().required(),
      cliente: Yup.mixed().required(),
      ...(isAdmin && {
        typeAprov: Yup.mixed().required(),
      }),
    }),
    Yup.object({
      tipoPago: Yup.mixed().required('Selecciona tipo de pago'),
      file: Yup.mixed()
        .required('Comprobante es requerido')
        .test('fileType', 'Solo se permiten archivos PDF', (value) => {
          if (!value) return false;
          return value.type === 'application/pdf';
        }),

      comments: Yup.mixed().required('Comentario es requerido'),
    }),
  ];
  const [currentSchema, setCurrentSchema] = useState(validationSchemas[0]);

  const formBalance = useFormik({
    validationSchema: currentSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: async (values, { setStatus, setSubmitting, setFieldError }) => {
      if (!stepper.current) {
        return;
      }
      switch (stepper.current.currentStepIndex) {
        case 1:
          setLoading(true);
          if (!stepper.current) {
            return;
          }
          const saldoVirtualAmount = values.virtual;
          const verifyRes = await verifyBalance(saldoVirtualAmount);
          if (verifyRes?.data?.doc?.data) {
            stepper.current.goNext();
            setCurrentSchema(validationSchemas[1]);
            setTextStepper('Continuar');
          } else {
            SimpleAlert(verifyRes.data.message, 3000, 'error');
          }
          setLoading(false);
          break;
        case 2:
          if (!stepper.current) {
            return;
          }
          setLoading(true);
          if (values.tipoPago) {
            setLoading(false);
            setAprovComplete(0);
            setTextStepper('Confirmar');
            stepper.current.goNext();
          }
          break;
        case 3:
          if (!stepper.current) {
            return;
          }

          setLoading(true);

          const body = {
            [isAdmin ? 'companyId' : 'distributorId']: values.empresa.id,
            saldoVirtualAmount: values.virtual,
            payType: values.tipoPago.id,
            pdfFile: values.file,
            comments: values.comments,
            ...(isConsignment ? { is_consignment: true } : {}),
          };

          approvisionamiento(body)
            .then((res) => {
              if (res && res.data) {
                setLoading(false);
                setTextStepper('Finalizar');
                setAprovComplete(1);

                if (stepper.current) {
                  stepper.current.goNext();
                } else {
                  SimpleAlert(
                    'Error: El Stepper no está inicializado.',
                    3000,
                    'error'
                  );
                }
              } else {
                SimpleAlert('Hubo un problema con la operación', 3000, 'error');
              }
            })
            .catch((err) => {
              let resMessageToast = '';
              if (err.response && err.response.data) {
                const {
                  data: { message, details = null },
                } = err.response;
                resMessageToast = `${message}`;
                if (details?.length) {
                  setStatus(`${details[0]?.message}`);
                }
              } else {
                resMessageToast = 'Error inesperado al realizar la operación';
              }
              setLoading(false);
              SimpleAlert(resMessageToast, 3000, 'error');
            });
          break;

        case 4:
          if (!stepper.current) {
            return;
          }

          stepper.current.goto(1);

          formBalance.resetForm({
            values: initialValues,
          });

          setTypeCommission('');
          setRadioSelection('');
          setFileName('');
          setTextStepper('Continuar');
          setCurrentSchema(validationSchemas[0]);
          setAprovComplete(0);
          setLoading(false);
          setCommission(0);

          handleClose(false);

          break;
      }
    },
  });

  const prevStep = () => {
    if (!stepper.current) {
      return;
    }
    stepper.current?.goPrev();
    setCurrentSchema(validationSchemas[stepper.current.currentStepIndex - 1]);
    setTextStepper('Continuar');
  };
  const handleTypeClientChange = (selectedOption: any) => {
    formBalance.setFieldValue('cliente', selectedOption);
    formBalance.setFieldValue('empresa', null);
    const client = selectedOption.value;

    if (client === 'socio') {
      setRadioSelection('SOC');
      setIsDistribuidor(false);
    }
    if (client === 'empresa') {
      setRadioSelection('EMP');
      setIsDistribuidor(false);
    }
    // if (client === 'distribuidor') {
    //   setRadioSelection('algo');

    //   setIsDistribuidor(true);
    // }
  };

  const handleTypeAprovChange = (selectedOption: any) => {
    formBalance.setFieldValue('typeAprov', selectedOption);

    const selctTypeAprov = selectedOption.value;
    if (selctTypeAprov === 'consig') setIsConsignment(true);
    if (selctTypeAprov === 'direct') setIsConsignment(false);
  };

  const handleDistribuidorChange = (value: any) => {
    if (value) {
      setTypeCommission(value.account.typeCommission.code);
      // setCommission(
      //   Number(
      //     commissions.find(
      //       (item) => item.type_account_id === value.account.type_id
      //     )?.percentage ?? 0
      //   ) / 100
      // );
      setCommission(Number(value.account.commission_percent) / 100);

      formBalance.setFieldValue('virtual', 0);
      formBalance.setFieldValue('efectivo', 0);
    }
    formBalance.setFieldValue('cliente', value);
    formBalance.setFieldValue('empresa', value);
  };

  const handleEmpresaChange = (value: any) => {
    if (value) {
      setTypeCommission(value.account.typeCommission.code);
      // setCommission(
      //   Number(
      //     commissions.find(
      //       (item) => item.type_account_id === value.account.type_id
      //     )?.percentage ?? 0
      //   ) / 100
      // );
      setCommission(Number(value.account.commission_percent) / 100);
      formBalance.setFieldValue('virtual', 0);
      formBalance.setFieldValue('efectivo', 0);
    }
    formBalance.setFieldValue('empresa', value);
  };

  const handleCloseModal = () => {
    setTimeout(() => {
      formBalance.resetForm();
      stepper.current?.goto(1);
      setCommission(0);
      setTypeCommission('');
      setRadioSelection('');
      setCurrentSchema(validationSchemas[0]);
      setLoading(false);
      setFileName('');
      setTextStepper('Continuar');
      setAprovComplete(0);
    }, 300);

    handleClose(false);
  };

  const onEffectiveChange = (values: any) => {
    const { floatValue } = values;
    formBalance.setFieldValue('efectivo', floatValue ?? 0);
    formBalance.setFieldValue(
      'virtual',
      floatValue ? floatValue * (1 + commission) : 0
    );
  };
  const onVirtualChange = (values: any) => {
    const { floatValue } = values;
    formBalance.setFieldValue('virtual', floatValue ?? 0);
    formBalance.setFieldValue(
      'efectivo',
      floatValue ? floatValue * (1 - commission) : 0
    );
  };

  const handleFileChange = (e: any) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setFileName(file.name);
      formBalance.setFieldValue('file', file);
    }
  };

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const tdp = await getSelectCatalog('TDP');
        // const comms = await getAllCommissions({
        //   type_operation: 'COS',
        // });
        const dist = await getAllSelect({});
        setDistribuidores(dist);
        if (userHasRole(['ADMIN_MEXABLOCK'], userRoles)) {
          setIsAdmin(true);
          const empRes = await getSelectEmpresas();
          setEmpresas(empRes);
        }
        if (userHasRole(['ADMIN_PARTNER'], userRoles)) {
          formBalance.setFieldValue('cliente', 'distribuidor');
        }

        if (tdp) setTiposPago(tdp);
        // if (comms) setCommisssions(comms);
      } catch (err) {
        console.error(err);
        history.push('/error/500');
      }
    };
    fetchPost();
  }, []);

  useEffect(() => {
    if (show) {
      loadStepper();
      formBalance.resetForm();
      setTextStepper('Continuar');
      setCurrentSchema(validationSchemas[0]);
      setAprovComplete(0);
      setFileName('');
    }
  }, [show]);

  useEffect(() => {
    if (isAdmin) {
      setCurrentSchema(validationSchemas[0]);
    }
  }, [isAdmin]);

  return (
    <>
      <Modal
        id="kt_modal_create"
        tabIndex={-1}
        aria-hidden="true"
        dialogClassName="modal-dialog modal-dialog-centered modal-xl"
        show={show}
        onHide={handleCloseModal}
      >
        <div className="modal-content">
          <div className="modal-header pb-0 border-0 justify-content-end">
            <div
              className="btn btn-sm btn-icon btn-active-color-primary"
              data-bs-dismiss="modal"
              onClick={handleCloseModal}
            >
              <KTSVG
                className="svg-icon-2"
                path="/media/icons/duotune/arrows/arr061.svg"
              />
            </div>
          </div>
          <div className="modal-body scroll-y mx-5 pt-0">
            <div className="text-center mb-5">
              <h1 className="mb-3">Aprovisionar Saldo</h1>
            </div>
            <div className="modal-body scroll-y mx-5 mx-xl-15 my-7">
              <div
                ref={stepperRef}
                className="stepper stepper-links d-flex flex-column gap-5 first"
                id="kt_modal_create_app_stepper"
              >
                <div className="stepper-nav justify-content-center py-2">
                  <div
                    className="stepper-item current"
                    data-kt-stepper-element="nav"
                  >
                    <h3 className="stepper-title">Validación de Saldo</h3>
                  </div>
                  <div
                    className="stepper-item pending"
                    data-kt-stepper-element="nav"
                  >
                    <h3 className="stepper-title">Método de pago </h3>
                  </div>
                  <div
                    className="stepper-item pending"
                    data-kt-stepper-element="nav"
                  >
                    <h3 className="stepper-title">Confirmación</h3>
                  </div>
                  <div
                    className="stepper-item pending"
                    data-kt-stepper-element="nav"
                  >
                    <h3 className="stepper-title">Transacción Exitosa</h3>
                  </div>
                </div>
                <form
                  className="form"
                  action="#"
                  onSubmit={formBalance.handleSubmit}
                >
                  <div
                    className="current min-h-550px"
                    data-kt-stepper-element="content"
                  >
                    <div className="w-100 h-auto ms-20 me-20">
                      {isAdmin ? (
                        <>
                          <div className="w-100 d-flex flex-column align-items-center">
                            <label className="required fw-bold fs-6 mb-2 w-auto">
                              Tipo de aprovisionamiento
                            </label>
                            <Select
                              options={typeAprov}
                              styles={styleSelect}
                              className="w-50"
                              menuPosition={'fixed'}
                              placeholder={
                                'Seleccione tipo de aprovisionamiento'
                              }
                              isSearchable={true}
                              noOptionsMessage={() => (
                                <span>No se encontraron registros</span>
                              )}
                              onChange={handleTypeAprovChange}
                              value={formBalance.values.typeAprov}
                              backspaceRemovesValue
                            />
                          </div>
                          <div
                            className="row justify-content-center mb-6"
                            style={{ minHeight: '20px' }}
                          >
                            {formBalance.touched.typeAprov &&
                              formBalance.errors.typeAprov && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">
                                    Es necesario seleccionar tipo de
                                    aprovisionamiento
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="w-100 d-flex flex-column align-items-center">
                            <label className="required fw-bold fs-6 mb-2 w-auto">
                              Tipo de cliente
                            </label>
                            <Select
                              options={typeClient}
                              styles={styleSelect}
                              className="w-50"
                              menuPosition={'fixed'}
                              placeholder={'Seleccione tipo de cliente'}
                              isSearchable={true}
                              noOptionsMessage={() => (
                                <span>No se encontraron registros</span>
                              )}
                              onChange={handleTypeClientChange}
                              value={formBalance.values.cliente}
                              backspaceRemovesValue
                            />
                          </div>
                          <div
                            className="row justify-content-center mb-4"
                            style={{ minHeight: '20px' }}
                          >
                            {formBalance.touched.cliente &&
                              formBalance.errors.cliente && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">
                                    Es necesario seleccionar tipo de cliente
                                  </span>
                                </div>
                              )}
                          </div>
                          <div className="row justify-content-center mb-15">
                            <div className="w-100 text-center">
                              <label className="required fw-bold fs-6 mb-2 w-auto">
                                Clientes
                              </label>
                            </div>
                            <Select
                              options={empresasOptions}
                              styles={styleSelect}
                              className="w-50"
                              menuPosition={'fixed'}
                              placeholder={'Seleccione cliente'}
                              isSearchable={true}
                              isDisabled={!radioSelection}
                              noOptionsMessage={() => (
                                <span>No se encontraron registros</span>
                              )}
                              onChange={handleEmpresaChange}
                              value={formBalance.values.empresa}
                              backspaceRemovesValue
                            />
                            <div
                              className="row justify-content-center mb-4"
                              style={{ minHeight: '20px' }}
                            >
                              {formBalance.touched.empresa &&
                                formBalance.errors.empresa && (
                                  <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                    <span role="alert">
                                      Es necesario seleccionar una empresa o
                                      socio
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="row justify-content-center mb-15">
                            <div className="col-md-3 fv-row fv-plugins-icon-container d-flex flex-column align-items-center">
                              <div className="w-100 text-center">
                                <label className="required fw-bold fs-6 mb-2 w-auto">
                                  Distribuidor
                                </label>
                              </div>
                              <Select
                                options={distribuidores}
                                styles={styleSelect}
                                menuPosition={'fixed'}
                                placeholder={'Seleccione'}
                                isSearchable={true}
                                noOptionsMessage={() => (
                                  <span>No se encontraron registros</span>
                                )}
                                onChange={handleDistribuidorChange}
                                value={formBalance.values.empresa}
                                backspaceRemovesValue
                              />
                              <div
                                className="row justify-content-center mb-4"
                                style={{ minHeight: 20 }}
                              >
                                {formBalance.touched.empresa &&
                                  formBalance.errors.empresa && (
                                    <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                      <span role="alert">
                                        Es necesario seleccionar un distribuidor
                                      </span>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      {!commission ? null : (
                        <>
                          <div className="row">
                            <div className="fv-row col mb-7 fv-plugins-icon-container">
                              <div className="w-100 d-flex">
                                <label className="required disabled fs-6 fw-semibold form-label mb-2">
                                  Efectivo
                                </label>
                              </div>
                              <NumericFormat
                                name="efectivo"
                                className="form-control mb-3"
                                value={formBalance.values.efectivo}
                                decimalScale={2}
                                decimalSeparator="."
                                fixedDecimalScale
                                placeholder="$ 0.00"
                                prefix="$ "
                                maxLength={16}
                                thousandSeparator=","
                                disabled={typeCommission !== 'SVV'}
                                onValueChange={onEffectiveChange}
                              />
                            </div>
                            <div className="fv-row col mb-7 fv-plugins-icon-container">
                              <div className="w-100 d-flex">
                                <label className="required disabled fs-6 fw-semibold form-label mb-2">
                                  Saldo Virtual
                                </label>
                              </div>
                              <NumericFormat
                                name="virtual"
                                className="form-control mb-3"
                                value={formBalance.values.virtual}
                                decimalScale={2}
                                decimalSeparator="."
                                fixedDecimalScale
                                placeholder="$ 0.00"
                                prefix="$ "
                                maxLength={16}
                                thousandSeparator=","
                                disabled={typeCommission !== 'EFF'}
                                onValueChange={onVirtualChange}
                              />
                            </div>
                          </div>
                          <div
                            className="row justify-content-center mb-1"
                            style={{ minHeight: '20px' }}
                          >
                            {formBalance.touched.virtual &&
                              formBalance.errors.virtual && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">Saldo Requerido</span>
                                </div>
                              )}
                          </div>
                        </>
                      )}
                    </div>
                  </div>

                  <div
                    data-kt-stepper-element="content"
                    className="pending min-h-500px"
                  >
                    <div className="row w-100 justify-content-center">
                      <div className="row justify-content-center mb-15">
                        <div className="fv-row fv-plugins-icon-container d-flex flex-column align-items-center">
                          <div className="w-100 text-center">
                            <label className="required fw-bold fs-6 mb-2 w-auto">
                              Tipo de Pago
                            </label>
                          </div>
                          <Select
                            options={tiposPago}
                            styles={styleSelect}
                            className="w-50"
                            menuPosition={'fixed'}
                            placeholder={'Seleccione'}
                            isSearchable={true}
                            noOptionsMessage={() => (
                              <span>No se encontraron registros</span>
                            )}
                            onChange={(val) =>
                              formBalance.setFieldValue('tipoPago', val)
                            }
                            value={formBalance.values.tipoPago}
                            backspaceRemovesValue
                          />
                          <div className="row justify-content-center mb-1">
                            {formBalance.touched.tipoPago &&
                              formBalance.errors.tipoPago && (
                                <div className="fv-plugins-message-container text-danger w-auto ms-5">
                                  <span role="alert">
                                    El tipo de pago es requerido
                                  </span>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="fv-row" style={{ textAlign: 'center' }}>
                        <h3 className="required">Comprobante de pago</h3>

                        <br></br>
                        <div className="text-center">
                          <img
                            src={toAbsoluteUrl('/media/svg/files/pdf.svg')}
                            alt="PDF"
                            className="w-10 mb-2"
                          />

                          <label
                            htmlFor="formFile"
                            className="text-primary text-decoration-underline d-block"
                            style={{ cursor: 'pointer' }}
                          >
                            {fileName || 'Seleccionar archivo'}
                          </label>

                          <input
                            type="file"
                            id="formFile"
                            className="d-none"
                            accept=".pdf"
                            onChange={handleFileChange}
                          />
                        </div>
                        {formBalance.touched.file && formBalance.errors.file && (
                          <div className="fv-plugins-message-container text-danger w-auto ms-5">
                            <span role="alert">{formBalance.errors.file}</span>
                          </div>
                        )}
                      </div>
                      <div className="fv-row">
                        <label
                          htmlFor="formComment"
                          className="fs-6 fw-semibold form-label mb-2 required"
                        >
                          Agregar comentario
                        </label>

                        <textarea
                          id="formComment"
                          className="form-control rounded-3 mb-5"
                          {...formBalance.getFieldProps('comments')}
                          rows={4}
                          style={{ resize: 'none' }}
                          maxLength={500}
                          placeholder="Escribe un comentario"
                        ></textarea>
                        {formBalance.touched.comments &&
                          formBalance.errors.comments && (
                            <div className="fv-plugins-message-container text-danger w-auto ms-5">
                              <span role="alert">
                                El comentario es requerido
                              </span>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                  <div
                    data-kt-stepper-element="content"
                    className="pending min-h-500px"
                  >
                    <div className="row w-100 d-flex flex-column justify-content-center align-items-center w-100">
                      <h1 className="fw-bolder text-center">
                        ¿Está seguro de realizar este movimiento?
                      </h1>
                      <span className="fw-bolder text-center fs-1">
                        Este cambio no se puede modificar.
                      </span>
                    </div>
                  </div>

                  <div
                    data-kt-stepper-element="content"
                    className="pending min-h-550px"
                  >
                    <div className="d-flex flex-column justify-content-center align-items-center w-100">
                      <h1 className="text-primary fw-bolder text-center">
                        El aprovisionamiento se ha realizado con exito{' '}
                      </h1>

                      <div className="w-350px rounded mx-auto d-block">
                        <AnimationSucces />
                      </div>
                    </div>
                  </div>
                  {/* <div className="fs-7 text-muted mb-15">
                    Tenga en cuenta que todos los cambios de saldo manuales
                    serán auditados por el equipo financiero. Por favor conserve
                    sus facturas y recibos hasta entonces. Gracias.
                  </div> */}
                  <div className="d-flex flex-stack pt-10">
                    <div className="me-2">
                      {aprovComplete === 0 && (
                        <button
                          onClick={prevStep}
                          type="button"
                          className="btn btn-lg btn-light-primary me-3"
                          data-kt-stepper-action="previous"
                        >
                          Regresar
                        </button>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="btn btn-primary"
                      data-kt-users-modal-action="submit"
                      disabled={loading}
                    >
                      {!loading && (
                        <span className="indicator-label">{textStepper}</span>
                      )}
                      {loading && (
                        <span
                          className="indicator-progress"
                          style={{ display: 'block' }}
                        >
                          Espere por favor...
                          <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
export { AprovisionamientoModal };
