import axios from 'axios';
import { maskPhoneNumber, cleanPhoneNumber } from '../../../_mexablock/helpers';
const API_URL = process.env.REACT_APP_API;

export async function getAll(filters: any) {
  const response = await axios.get(`${API_URL}catalogos/distributor`);
  const data = response.data.doc.data;
  return data;
}
export async function getAllSelect(filters: any) {
  const data = await getAll(filters);
  return data.rows.map((row: any) => ({
    ...row,
    label:
      row.name.trim() ||
      `${row.first_name?.trim() || ''} ${
        row.father_last_name?.trim() || ''
      }`.trim() ||
      'Sin nombre',
    value: row.id,
  }));
}
export async function getAllInactive(filters: any) {
  const response = await axios.get(`${API_URL}catalogos/partner/inactive`);
  const data = response.data.doc.data;
  return data;
}

export async function getOneSocios(id_row: number) {
  const response = await axios.get(`${API_URL}catalogos/partner/${id_row}`);
  const {
    partner,
    id,
    alias,
    first_name,
    father_last_name,
    mother_last_name,
    email,
    phone,
    curp,
    rfc,
    birthdate,
    active,
    gender,
  } = response.data.doc.data;
  return {
    id: partner.id || 0,
    name: partner.name || '',
    business_name: partner.business_name || '',
    code: partner.code || '',
    commission: partner.commission || 0,
    manager: {
      id: id || 0,
      alias: alias || '',
      first_name: first_name || '',
      father_last_name: father_last_name || '',
      mother_last_name: mother_last_name || '',
      email: email || '',
      phone: maskPhoneNumber(phone) || '',
      phoneFormat: cleanPhoneNumber(phone) || '',
      curp: curp || '',
      rfc: rfc || '',
      birthdate: birthdate || new Date(),
      active: active || false,
      gender: {
        id: gender.id || 0,
        code: gender.code || '',
        name: gender.name || '',
      },
    },
  };
}

export async function addDistribuidor(values: any) {
  let body = values;

  let formData = new FormData();
  formData.append('type_contributor_id', body.type_contributor_id);
  formData.append('street', body.street);
  formData.append('city', body.city);
  formData.append('state', body.state);
  formData.append('postal_code', body.postal_code);
  formData.append('type_commission_id', body.type_commission_id);
  formData.append('commission_percent', body.commission_percent);
  formData.append('name', body.name);
  formData.append('business_name', body.business_name);
  formData.append('first_name', body.first_name);
  formData.append('father_last_name', body.father_last_name);
  formData.append('mother_last_name', body.mother_last_name);
  formData.append('birthdate', body.birthdate);
  formData.append('gender_id', body.gender_id);
  formData.append('email', body.email);
  formData.append('phone', body.phone);
  formData.append('interbank_key', body.interbank_key);
  formData.append('bank_name', body.bank_name);
  formData.append('account_owner_first_name', body.account_owner_first_name);
  formData.append('account_owner_last_name', body.account_owner_last_name);
  formData.append('cfdi_id', body.cfdi_id);
  formData.append('billing_mail', body.billing_mail);
  formData.append('rfc', body.rfc);
  formData.append('pdfFile', body.pdfFile);
  const response = await axios({
    method: 'post',
    url: `${API_URL}catalogos/distributor/`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
}

export async function updDistribuidor(values: any, id: number) {
  let body = values;
  let idDistribuidor = id;
  console.log('valores update: ', body);
  let formData = new FormData();
  if (body.type_contributor_id === 2) {
    formData.append('street', body.street);
    formData.append('city', body.city);
    formData.append('state', body.state);
    formData.append(
      'postal_code',
      body.postal_code === 0 ? '' : body.postal_code
    );
  }
  if (body.interbank_key && body.interbank_key.trim() !== '') {
    formData.append('interbank_key', body.interbank_key);
  }

  formData.append('type_contributor_id', body.type_contributor_id);
  formData.append('type_commission_id', body.type_commission_id);
  formData.append('commission_percent', body.commission_percent);
  formData.append('name', body.name);
  formData.append('business_name', body.business_name);
  formData.append('first_name', body.first_name);
  formData.append('father_last_name', body.father_last_name);
  formData.append('mother_last_name', body.mother_last_name);
  formData.append('birthdate', body.birthdate);
  formData.append('gender_id', body.gender_id);
  formData.append('email', body.email);
  formData.append('phone', body.phone);
  formData.append('bank_name', body.bank_name);
  formData.append('account_owner_first_name', body.account_owner_first_name);
  formData.append('account_owner_last_name', body.account_owner_last_name);
  formData.append('cfdi_id', body.cfdi_id);
  formData.append('billing_mail', body.billing_mail);
  formData.append('rfc', body.rfc);
  formData.append('pdfFile', body.pdfFile);
  const response = await axios({
    method: 'put',
    url: `${API_URL}catalogos/distributor/${idDistribuidor}`,
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
  return response;
}

export async function updateSocios(body: any) {
  const {
    id: partner_id,
    name,
    business_name,
    commission,
    manager: {
      id: user_id,
      email,
      first_name,
      father_last_name,
      mother_last_name,
      phoneFormat,
      curp,
      rfc,
      birthdate,
      gender,
    },
  } = body;
  const data = {
    name: name,
    business_name: business_name,
    commission: commission,
    manager: {
      email: email,
      first_name: first_name,
      father_last_name: father_last_name,
      mother_last_name: mother_last_name,
      phone: phoneFormat,
      curp: curp,
      rfc: rfc,
      birthdate: birthdate,
      gender_id: gender.id === 0 ? null : gender.id,
    },
  };
  const response = await axios.put(
    `${API_URL}catalogos/partner/${partner_id}/user/${user_id}`,
    data
  );
  return response;
}

export async function removeSocio(id: number) {
  const response = await axios.delete(`${API_URL}catalogos/partner/${id}`);
  return response;
}

export async function activeDistribuidor(values: any) {
  const { id, stripe_id } = values;
  const response = await axios.put(`${API_URL}catalogos/partner/active`, {
    distributor_id: id,
    stripe_id: stripe_id,
  });
  return response;
}

export async function getAllDistributorsForReport() {
  const response = await axios.get(`${API_URL}catalogos/partner/report/list`);
  const data = response.data.doc.data.rows;
  return data;
}

export const desactivarDistribuidor = async (id: number) => {
  const response = await axios.delete(`${API_URL}catalogos/distributor/${id}`);
  return response;
};
